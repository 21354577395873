<div class="modal-header">
    <h3 class="modal-title">{{ 'modals.titles.createParty' | translate }}</h3>
    <button type="button" class="close icon p-1" (click)="onClose()">close</button>
</div>
<div class="modal-body">
    <div class="section-forms" [formGroup]="formGroup">
            <div class="form-group  col-sm-6">
                <app-form-item [field]="fields.leiCode" [focus]="leiCode">
                    <input class="form-control" type="text" [formControlName]="fields.leiCode"
                        [placeholder]="placeholder(fields.leiCode) | translate" component #leiCode />
                </app-form-item>
            </div>
            <div class="form-group  col-sm-6">
                <app-form-item [field]="fields.idBdrParty" [focus]="idBdrParty">
                    <input class="form-control" type="text" [formControlName]="fields.idBdrParty"
                        [placeholder]="placeholder(fields.idBdrParty) | translate" component #idBdrParty />
                </app-form-item>
            </div>
            <div class="form-group  col-sm-6">
                <app-form-item [field]="fields.description" [focus]="description">
                    <input class="form-control" type="text" [formControlName]="fields.description"
                        [placeholder]="placeholder(fields.description) | translate" component #description />
                </app-form-item>
            </div>
</div>
<div class="modal-footer">
    <button type="button" (click)="onCancel()" (keyup.enter)="onCancel()" class="btn btn-lg btn-link">{{ cancelButton |
        translate }}</button>
    <button ngbAutofocus type="button" (click)="onConfirm()" (keyup.enter)="onConfirm()" class="btn btn-lg btn-primary"
        translate>{{ confirmButton | translate }}</button>
</div>

export const PIWIK_CONST = {
  config: {
    trakcerUrl: 'piwik.php',
    scriptFileName: 'piwik.js'
  },
  methods: {
    trackEvent: 'trackEvent',
    trackPageView: 'trackPageView'
  },
  eventCategories: {
    Events: 'Events',
    Instruments: 'Instruments',
    Positions: 'Positions',
    Instructions: 'Instructions',
    Programmes: 'Programmes',
    Valuation: 'Valuation',
    Payment: 'Payment',
    SSI: 'SSI'
  },
  eventActions: {
    onSubmit: 'onSubmitClicked',
    onSearchClicked: 'onSearchClicked',
    onDownloadClicked: 'onExportClicked',
    onSaveClicked: 'onSaveClicked',
    onCancelClicked: 'onCancelClicked',
    onCloseClicked: 'onCloseClicked',
    onCreateEventClicked: 'onCreateEventClicked',
    onRegenrateClicked: 'onRegenrateClicked',
    onEditClicked: 'onEditClicked',
    onBackClicked: 'onBackClicked',
    updatePosition: 'updatePosition',
    updateEvents: 'updateEvents',
    updateValuation: 'updateValuation',
    updatePayment: 'updatePayment',
    updateInstrument: 'updateInstrument',
    updateTranche: 'updateTranche',
    updateFailure: 'updateFailure'
  }
};

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InstructionModel, toInstructionModel } from '../model/instruction.model';
import { PartyResourceService, ExtCashAccountDto, ExtCashAccountLevelDto } from '@apis/backend/instruments';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InstructionService {
  constructor(private readonly partyResourceService: PartyResourceService) {}
  get(): Observable<InstructionModel[]> {
    return this.partyResourceService.getAllExtCashAccount().pipe(map(dto => dto.resources?.map(el => toInstructionModel(el)) ?? []));
  }

  public createExteCashAccount(extCash: ExtCashAccountLevelDto): Observable<InstructionModel> {
    return this.partyResourceService.createExtCashAccount(extCash).pipe(map(result => toInstructionModel(result as ExtCashAccountDto)));
  }

  public updateExtCashAccount(extCash: ExtCashAccountLevelDto): Observable<InstructionModel> {
    return this.partyResourceService.updateExtCashAccountTable(extCash).pipe(map(result => toInstructionModel(result as ExtCashAccountDto)));
  }

  public createInstrumentParty(leiCode: string, bdrId: string, description: string): Observable<any> {
    return this.partyResourceService.createInstrumentParty(leiCode, bdrId, description);
  }

  public getSSIDetails(currency: string, bdrId: string): Observable<any | null> {
    return this.partyResourceService.getExtCashAccount(currency, '', bdrId);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ColDef, FirstDataRenderedEvent, GridApi, GridOptions } from 'ag-grid-community';
import { InstructionModel } from '../model/instruction.model';
import { InstructionColumnDefService } from '../services/instructions-column-def-service';
import { InstructionModalsService } from '../services/instructions-modals.service';
import { InstructionService } from '../services/instruction.service';
import { InstructionFormService } from '../services/instruction-form.service';
import { ExtCashAccountModel, toExtCashAccountLevelDto } from '../model/extCashAccountModel';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { PartyFormService } from '../services/party.form.service';
import { PIWIK_CONST } from 'src/app/core/analytics/piwik-tracking.constants';
import { Track } from '@sgwt/analytics-core';

@Component({
  selector: 'app-instructions-list',
  templateUrl: './instructions-list.component.html',
  providers: [InstructionModalsService]
})
export class InstructionsListComponent implements OnInit {
  @Input() instructions: InstructionModel[] = [];
  public loading = false;
  public columnDefs: ColDef[] = [];
  public defaultColDef: ColDef | undefined;
  public gridOptions!: GridOptions;
  extCash: Nullable<ExtCashAccountModel> | undefined;
  public rowGroupPanelShow: 'always' | 'onlyWhenGrouping' | 'never' = 'always';
  public sideBar: any = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true
        }
      }
    ],
    defaultToolPanel: ''
  };
  constructor(
    private readonly instructionColumnDefService: InstructionColumnDefService,
    private readonly instructionModalService: InstructionModalsService,
    private readonly instructionService: InstructionService,
    private readonly instructionFormService: InstructionFormService,
    private readonly partyFormService: PartyFormService,
    private readonly toastManagerService: ToastManagerService
  ) {}
  ngOnInit(): void {
    this.columnDefs = this.instructionColumnDefService.get();

    this.gridOptions = {
      onGridSizeChanged: () => {
        this.gridOptions.api?.sizeColumnsToFit();
      },
      onToolPanelVisibleChanged: () => {
        this.columnResize();
      },
      onColumnVisible: () => {
        this.columnResize();
      },
      pagination: true,
      suppressPaginationPanel: false,
      paginationPageSize: 20,
      enableCellTextSelection: true,
      ensureDomOrder: true,
      suppressContextMenu: true,
      cacheQuickFilter: true,
      stopEditingWhenCellsLoseFocus: true
    };
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      lockPinned: true,
      enableValue: false,
      enableRowGroup: true,
      enablePivot: false,
      sortable: true,
      filter: true,
      menuTabs: ['filterMenuTab'],
      resizable: true
    };
  }

  onGridReady(params: { api: GridApi }): void {
    const gridApi = params.api;
    gridApi.sizeColumnsToFit();
    gridApi.addGlobalListener(this.resizeColumn);
    gridApi.setDomLayout('autoHeight');
    gridApi.refreshHeader();
  }

  onFirstDataRendered(params: FirstDataRenderedEvent): void {
    params.api.sizeColumnsToFit();
  }

  private readonly resizeColumn = (event: any) => {
    if (['gridSizeChanged', 'toolPanelVisibleChanged', 'columnVisible', 'rowDataChanged'].includes(event)) {
      this.columnResize();
    }
  };

  private columnResize() {
    setTimeout(() => {
      this.gridOptions.api?.sizeColumnsToFit();
    });
  }

  @Track([PIWIK_CONST.methods.trackEvent, PIWIK_CONST.eventCategories.Instructions, PIWIK_CONST.eventActions.onDownloadClicked])
  public exportCsv(): void {
    this.gridOptions.api?.exportDataAsCsv();
  }

  public createSSI(): void {
    this.instructionFormService.reset();
    this.instructionFormService.clearInitialValue();
    const modal = this.instructionModalService.openCreateSSIModal();
    modal.result.then((res: DialogResult) => {
      if (res !== 'confirm') {
        this.instructionFormService.reset();
        return;
      }
      const extCash = this.instructionFormService.rawValue();
      this.instructionService.createExteCashAccount(toExtCashAccountLevelDto(extCash as ExtCashAccountModel)).subscribe(result => {
        const message = ' ';
        if (result) {
          this.toastManagerService.toastSuccess('toasts.instructions.ssiCreated.title', message);
        } else {
          this.toastManagerService.toastWarning('toasts.instructions.ssiError.title', message);
        }
        this.instructionFormService.reset();
        this.instructionFormService.clearInitialValue();
      });
    });
  }

  public ModifySSI(): void {
    this.instructionFormService.reset();
    this.instructionFormService.clearInitialValue();
    const modal = this.instructionModalService.openModifySSIModal();
    modal.result.then((res: DialogResult) => {
      if (res !== 'confirm') {
        return;
      }
      const extCash = this.instructionFormService.rawValue();
      this.instructionService.updateExtCashAccount(toExtCashAccountLevelDto(extCash as ExtCashAccountModel)).subscribe(result => {
        const message = ' ';
        if (result) {
          this.toastManagerService.toastSuccess('toasts.instructions.ssiModified.title', message);
        } else {
          this.toastManagerService.toastWarning('toasts.instructions.ssiError.title', message);
        }
        this.instructionFormService.reset();
        this.instructionFormService.clearInitialValue();
      });
    });
  }

  public createParty(): void {
    const modal = this.instructionModalService.openCreatePartyModal();
    modal.result.then((res: DialogResult) => {
      if (res !== 'confirm') {
        return;
      }
      const party = this.partyFormService.rawValue();
      this.instructionService.createInstrumentParty(party.leiCode!, party.idBdrParty!, party.description!).subscribe(result => {
        const message = ' ';
        if (result) {
          this.toastManagerService.toastSuccess('toasts.instructions.partyCreated.title', message);
        } else {
          this.toastManagerService.toastWarning('toasts.instructions.ssiError.title', message);
        }
        this.partyFormService.reset();
      });
    });
  }
}

import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FormService } from '@shared/services/forms/form.service';
import { ExtCashAccountModel } from '../model/extCashAccountModel';

@Injectable({
  providedIn: 'root'
})
export class InstructionFormService extends FormService<ExtCashAccountModel> {
  constructor(fb: UntypedFormBuilder) {
    super('instructions', fb, {
      idBdrParty: fb.control(null, [Validators.required]),
      currency: fb.control(null, [Validators.required]),
      correspondBankName: fb.control(null),
      correspondBankBIC: fb.control(null),
      intermediaryName: fb.control(null),
      intermediaryBIC: fb.control(null),
      account: fb.control(null),
      internalAccountName: fb.control(null, [Validators.required]),
      correspondBankAccount: fb.control(null),
      accountBIC: fb.control(null)
    });
  }
}

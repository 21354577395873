/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.59
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ExtCashAccountLevelDto { 
    idBdrParty?: string;
    currency?: string;
    correspondBankName?: string;
    correspondBankBIC?: string;
    intermediaryName?: string;
    intermediaryBIC?: string;
    account?: string;
    internalAccountName?: string;
    correspondBankAccount?: string;
    accountBic?: string;
}


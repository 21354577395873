import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { routeToEvent } from '@events/routes';
import { EventsService } from '@shared/services/events.service';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { EventModalsService, EventDetailFormService } from '@events/services';
import { EventDetailModel, CouponFrequencies } from '@events/models';
import { EVENT_CONSTANTS as E } from '@events/configs/event-constants';
import { PIWIK_CONST } from 'src/app/core/analytics/piwik-tracking.constants';
import { Track } from '@sgwt/analytics-core';

@Component({
  selector: 'app-event-save-cancel',
  templateUrl: './event-save-cancel.component.html',
  providers: [EventModalsService]
})
export class EventSaveCancelComponent {
  @Output() public save = new EventEmitter<boolean>();
  calculatedAmountPerDenom: string | undefined;
  eventDetails: Nullable<EventDetailModel> | undefined;
  @Input() public disableSaveButton: boolean | undefined;
  constructor(
    public eventDetailFormService: EventDetailFormService,
    private readonly eventService: EventsService,
    private readonly modalsService: EventModalsService,
    private readonly router: Router,
    private readonly toastService: ToastManagerService
  ) {}

  onSaveClick() {
    const formEdited = this.eventDetailFormService.touched && this.eventDetailFormService.dirty;
    this.eventDetailFormService.updateValueAndValidity();
    const errors = this.eventDetailFormService.errors();
    for (const i in errors) {
      if (!errors[i].required) {
        this.toastService.toastWarning('toasts.instruments.invalid-form.title', 'toasts.instruments.invalid-form.message');
        return;
      }
    }

    this.eventDetails = this.eventDetailFormService.rawValue();
    const amountPerDenom = this.eventDetails.amountPerDenomination ? this.formatedNumber(this.eventDetails.amountPerDenomination.toString()) : undefined;
    const eventType = this.eventDetails.eventType;
    const currency = this.eventDetails.paymentCurrency || undefined;
    switch (eventType) {
      case E.eventTypes.INTR: {
        this.intrAmountPerDenomAlert();
        break;
      }
      case E.eventTypes.PRED: {
        this.predAmountPerDenomAlert();
        break;
      }
      case E.eventTypes.REDM:
      case E.eventTypes.MCAL: {
        this.redmAndMcalAmountPerDenomAlert();
        break;
      }
    }
    const modal = this.modalsService.openSaveModal(amountPerDenom, this.calculatedAmountPerDenom ?? '', currency);
    modal.result.then((res: DialogResult) => {
      if (res !== 'confirm') {
        return;
      } else if (this.eventDetails) {
        this.saveEvent(this.eventDetails as EventDetailModel, formEdited);
      }
    });
  }

  intrAmountPerDenomAlert() {
    if (this.eventDetails && (this.eventDetails.interestRate || this.eventDetails.interestRate === 0)) {
      const denomination = this.eventDetails.denominationAsString ? this.eventDetails.denominationAsString.replace(/,/gi, '') : 1;
      const interestRate = this.eventDetails.interestRate / 100;
      const numberOfDays = this.eventDetails.numOfDayOfThePeriod;
      const currentPoolFactor = this.eventDetails.previousPoolFactor ? this.eventDetails.previousPoolFactor : 1;
      let daysByDayCountFraction = 0;
      if (this.eventDetails.dayCountFraction === E.dayCountFraction.EXA_EXA_ICMA_ISMA) {
        for (const frequency in CouponFrequencies) {
          if (frequency === this.eventDetails.couponFrequency) {
            daysByDayCountFraction = 1 / Number(CouponFrequencies[frequency]);
            break;
          }
        }
      } else {
        const dayCountFractionNumber = Number(this.eventDetails.dayCountFraction?.split('/')[1]?.split(' ')[0]) || 1;
        daysByDayCountFraction = dayCountFractionNumber !== 1 && numberOfDays ? numberOfDays / dayCountFractionNumber : 1;
      }
      this.calculatedAmountPerDenom = this.formatedNumber((Number(denomination) * Number(interestRate) * Number(daysByDayCountFraction) * Number(currentPoolFactor)).toString());
    } else {
      this.calculatedAmountPerDenom = undefined;
    }
  }

  redmAndMcalAmountPerDenomAlert() {
    if (this.eventDetails && (this.eventDetails.eventRedemptionUnit || this.eventDetails.eventRedemptionPrice)) {
      const denomination = this.eventDetails.denominationAsString ? this.eventDetails.denominationAsString.replace(/,/gi, '') : 1;
      let redemptionRate;
      if (this.eventDetails.tradingMethodIdentifier === E.tradingMethod.UNIT && this.eventDetails.eventRedemptionUnit) {
        redemptionRate = Number(this.eventDetails.eventRedemptionUnit.replace(/,/gi, ''));
      } else if (this.eventDetails.eventRedemptionPrice) {
        redemptionRate = Number(this.eventDetails.eventRedemptionPrice.replace(/,/gi, ''));
        if (redemptionRate && redemptionRate !== 1) {
          redemptionRate = redemptionRate / 100;
        }
      } else {
        this.calculatedAmountPerDenom = undefined;
        return;
      }
      const currentPoolFactor = this.eventDetails.previousPoolFactor ? this.eventDetails.previousPoolFactor : 1;
      this.calculatedAmountPerDenom = this.formatedNumber((Number(denomination) * Number(redemptionRate) * Number(currentPoolFactor)).toString());
    } else {
      this.calculatedAmountPerDenom = undefined;
    }
  }

  predAmountPerDenomAlert() {
    if (this.eventDetails && (this.eventDetails.partialRedemptionRate || this.eventDetails.partialRedemptionRate === 0)) {
      const denomination = this.eventDetails.denominationAsString ? this.eventDetails.denominationAsString.replace(/,/gi, '') : 1;
      const partialRedemptionRate = this.eventDetails.partialRedemptionRate / 100;
      this.calculatedAmountPerDenom = this.formatedNumber((Number(denomination) * partialRedemptionRate).toString());
    } else {
      this.calculatedAmountPerDenom = undefined;
    }
  }

  formatedNumber = (val: string | null): string | undefined => {
    if (val) {
      const numberParts = val.toString().split('.');
      const integerPart = numberParts[0];
      const decimalPart = numberParts[1];

      const arrayValue = integerPart.toLocaleString().match(/.{1,3}(?=(.{3})*$)/g) || [];
      const valueFormatted = arrayValue.join(',');
      if (decimalPart) {
        return `${valueFormatted}.${decimalPart.slice(0, 15)}`;
      }
      return valueFormatted;
    }
    return undefined;
  };

  @Track([PIWIK_CONST.methods.trackEvent, PIWIK_CONST.eventCategories.Events, PIWIK_CONST.eventActions.onCancelClicked])
  onCancelClick() {
    if (this.eventDetailFormService.dirty) {
      const modal = this.modalsService.openCancelModal();
      modal.result.then((res: DialogResult) => {
        if (res !== 'confirm') {
          return;
        }
        this.goBack();
      });
      return;
    }
    this.goBack();
  }

  @Track([PIWIK_CONST.methods.trackEvent, PIWIK_CONST.eventCategories.Events, PIWIK_CONST.eventActions.onSaveClicked])
  saveEvent(eventDetails: EventDetailModel, formEdited: boolean) {
    this.disableSaveButton = true;
    if (formEdited) {
      //piwik Event Tracking
      (window as any)._paq.push(['trackEvent', PIWIK_CONST.eventCategories.Events, PIWIK_CONST.eventActions.updateEvents]);
      this.eventService.updateEventDetails(eventDetails).subscribe(result => {
        if (result) {
          this.disableSaveButton = false;
          this.save.emit(true);
        }
      });
    } else {
      this.disableSaveButton = false;
      this.save.emit(true);
    }
  }

  @Track([PIWIK_CONST.methods.trackEvent, PIWIK_CONST.eventCategories.Events, PIWIK_CONST.eventActions.onBackClicked])
  private goBack() {
    const eventId = this.eventDetailFormService.rawValue('eventId');
    this.router.navigate(routeToEvent(eventId, 'consult'));
  }
}

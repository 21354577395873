/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.59
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { BdrIdDataDto } from '../model/models';
import { ExtCashAccountDto } from '../model/models';
import { ExtCashAccountLevelDto } from '../model/models';
import { Problem } from '../model/models';
import { ResultExtCashAccountDto } from '../model/models';
import { ResultPartyDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class PartyResourceService {

    protected basePath = 'https://issuer-ipa-develop.dev.socgen';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * createExtCashAccount
     * Create an External Cash Account  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.extcashaccount.create; Authorities: SGM_ISS_PAY_api.issuer-ipa.extcashaccount.create
     * @param extCashAccountLevelDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createExtCashAccount(extCashAccountLevelDto: ExtCashAccountLevelDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<ExtCashAccountDto>;
    public createExtCashAccount(extCashAccountLevelDto: ExtCashAccountLevelDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<ExtCashAccountDto>>;
    public createExtCashAccount(extCashAccountLevelDto: ExtCashAccountLevelDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<ExtCashAccountDto>>;
    public createExtCashAccount(extCashAccountLevelDto: ExtCashAccountLevelDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (extCashAccountLevelDto === null || extCashAccountLevelDto === undefined) {
            throw new Error('Required parameter extCashAccountLevelDto was null or undefined when calling createExtCashAccount.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ExtCashAccountDto>(`${this.configuration.basePath}/api/v1/parties/createExtCashAccount`,
            extCashAccountLevelDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createInstrumentParty
     * Create a new Instrument Party and Party External Reference  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.referential.party.create; Authorities: SGM_ISS_PAY_api.issuer-ipa.referential.party.create
     * @param leiCode LEI CODE
     * @param bdrId BDR ID
     * @param description description
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createInstrumentParty(leiCode: string, bdrId: string, description: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any>;
    public createInstrumentParty(leiCode: string, bdrId: string, description: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<any>>;
    public createInstrumentParty(leiCode: string, bdrId: string, description: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<any>>;
    public createInstrumentParty(leiCode: string, bdrId: string, description: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (leiCode === null || leiCode === undefined) {
            throw new Error('Required parameter leiCode was null or undefined when calling createInstrumentParty.');
        }
        if (bdrId === null || bdrId === undefined) {
            throw new Error('Required parameter bdrId was null or undefined when calling createInstrumentParty.');
        }
        if (description === null || description === undefined) {
            throw new Error('Required parameter description was null or undefined when calling createInstrumentParty.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (leiCode !== undefined && leiCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>leiCode, 'leiCode');
        }
        if (bdrId !== undefined && bdrId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>bdrId, 'bdrId');
        }
        if (description !== undefined && description !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>description, 'description');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/parties/createInstrumentParty`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllExtCashAccount
     * getAllExtCashAccount  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.extcashaccount.multiple.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.extcashaccount.multiple.read
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllExtCashAccount(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<ResultExtCashAccountDto>;
    public getAllExtCashAccount(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<ResultExtCashAccountDto>>;
    public getAllExtCashAccount(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<ResultExtCashAccountDto>>;
    public getAllExtCashAccount(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResultExtCashAccountDto>(`${this.configuration.basePath}/api/v1/parties/ext-cash-accounts`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getExtCashAccount
     * getExtCashAccount  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.extcashaccount.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.extcashaccount.read
     * @param currency Currency
     * @param leiCode LEI CODE
     * @param bdrId BDR ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getExtCashAccount(currency: string, leiCode?: string, bdrId?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<ExtCashAccountDto>;
    public getExtCashAccount(currency: string, leiCode?: string, bdrId?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<ExtCashAccountDto>>;
    public getExtCashAccount(currency: string, leiCode?: string, bdrId?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<ExtCashAccountDto>>;
    public getExtCashAccount(currency: string, leiCode?: string, bdrId?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (currency === null || currency === undefined) {
            throw new Error('Required parameter currency was null or undefined when calling getExtCashAccount.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (leiCode !== undefined && leiCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>leiCode, 'leiCode');
        }
        if (bdrId !== undefined && bdrId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>bdrId, 'bdrId');
        }
        if (currency !== undefined && currency !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>currency, 'currency');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ExtCashAccountDto>(`${this.configuration.basePath}/api/v1/parties/ext-cash-accounts/do-search`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get bdr data from leiCode
     * Get bdr data from leiCode  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.referential.parties; Authorities: SGM_ISS_PAY_api.issuer-ipa.referential.parties
     * @param leiCode LEI CODE of issuer.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIssuerBdrDataFromLeicode(leiCode: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<BdrIdDataDto>;
    public getIssuerBdrDataFromLeicode(leiCode: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<BdrIdDataDto>>;
    public getIssuerBdrDataFromLeicode(leiCode: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<BdrIdDataDto>>;
    public getIssuerBdrDataFromLeicode(leiCode: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (leiCode === null || leiCode === undefined) {
            throw new Error('Required parameter leiCode was null or undefined when calling getIssuerBdrDataFromLeicode.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (leiCode !== undefined && leiCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>leiCode, 'leiCode');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<BdrIdDataDto>(`${this.configuration.basePath}/api/v1/parties/_bdrDataFromLeicode`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Parties by role name
     * Return list of parties corresponding of a role name  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.referential.parties; Authorities: SGM_ISS_PAY_api.issuer-ipa.referential.parties
     * @param roleName Name of role.
     * @param typeFilter Type of filter for list of parties. Possibility of value &#x3D; PRG for Programme, INS for Instrument
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPartiesByRoles(roleName: 'CALCULATION_AGENT' | 'COMMON_DEPOSITARY' | 'COMMON_SAFEKEEPER' | 'COMPETENT_AUTHORITY' | 'TRANSFER_AGENT' | 'ESCROW_MANAGER' | 'FISCAL_AGENT' | 'ISSUER' | 'ISSUING_AGENT' | 'LISTING_AGENT' | 'LOCAL_PAYING_AGENT' | 'PRINCIPAL_PAYING_AGENT' | 'REGISTRAR' | 'REIMBURSMENT_CALCULATION_AGENT' | 'CLEARING_SYSTEM' | 'CASH_MANAGER' | 'COMMON_SERVICE_PROVIDER' | 'DESK_CALCULATION_AGENT', typeFilter: 'ALL' | 'PROGRAMME' | 'INSTRUMENT' | 'OPERATION', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<ResultPartyDto>;
    public getPartiesByRoles(roleName: 'CALCULATION_AGENT' | 'COMMON_DEPOSITARY' | 'COMMON_SAFEKEEPER' | 'COMPETENT_AUTHORITY' | 'TRANSFER_AGENT' | 'ESCROW_MANAGER' | 'FISCAL_AGENT' | 'ISSUER' | 'ISSUING_AGENT' | 'LISTING_AGENT' | 'LOCAL_PAYING_AGENT' | 'PRINCIPAL_PAYING_AGENT' | 'REGISTRAR' | 'REIMBURSMENT_CALCULATION_AGENT' | 'CLEARING_SYSTEM' | 'CASH_MANAGER' | 'COMMON_SERVICE_PROVIDER' | 'DESK_CALCULATION_AGENT', typeFilter: 'ALL' | 'PROGRAMME' | 'INSTRUMENT' | 'OPERATION', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<ResultPartyDto>>;
    public getPartiesByRoles(roleName: 'CALCULATION_AGENT' | 'COMMON_DEPOSITARY' | 'COMMON_SAFEKEEPER' | 'COMPETENT_AUTHORITY' | 'TRANSFER_AGENT' | 'ESCROW_MANAGER' | 'FISCAL_AGENT' | 'ISSUER' | 'ISSUING_AGENT' | 'LISTING_AGENT' | 'LOCAL_PAYING_AGENT' | 'PRINCIPAL_PAYING_AGENT' | 'REGISTRAR' | 'REIMBURSMENT_CALCULATION_AGENT' | 'CLEARING_SYSTEM' | 'CASH_MANAGER' | 'COMMON_SERVICE_PROVIDER' | 'DESK_CALCULATION_AGENT', typeFilter: 'ALL' | 'PROGRAMME' | 'INSTRUMENT' | 'OPERATION', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<ResultPartyDto>>;
    public getPartiesByRoles(roleName: 'CALCULATION_AGENT' | 'COMMON_DEPOSITARY' | 'COMMON_SAFEKEEPER' | 'COMPETENT_AUTHORITY' | 'TRANSFER_AGENT' | 'ESCROW_MANAGER' | 'FISCAL_AGENT' | 'ISSUER' | 'ISSUING_AGENT' | 'LISTING_AGENT' | 'LOCAL_PAYING_AGENT' | 'PRINCIPAL_PAYING_AGENT' | 'REGISTRAR' | 'REIMBURSMENT_CALCULATION_AGENT' | 'CLEARING_SYSTEM' | 'CASH_MANAGER' | 'COMMON_SERVICE_PROVIDER' | 'DESK_CALCULATION_AGENT', typeFilter: 'ALL' | 'PROGRAMME' | 'INSTRUMENT' | 'OPERATION', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (roleName === null || roleName === undefined) {
            throw new Error('Required parameter roleName was null or undefined when calling getPartiesByRoles.');
        }
        if (typeFilter === null || typeFilter === undefined) {
            throw new Error('Required parameter typeFilter was null or undefined when calling getPartiesByRoles.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (roleName !== undefined && roleName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>roleName, 'roleName');
        }
        if (typeFilter !== undefined && typeFilter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>typeFilter, 'typeFilter');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResultPartyDto>(`${this.configuration.basePath}/api/v1/parties/_partiesByRole`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Parties by lei code
     * Return the party corresponding to lei code  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.referential.parties; Authorities: SGM_ISS_PAY_api.issuer-ipa.referential.parties
     * @param leiCode LEI CODE of party
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPartyByLeiCode(leiCode: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any>;
    public getPartyByLeiCode(leiCode: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<any>>;
    public getPartyByLeiCode(leiCode: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<any>>;
    public getPartyByLeiCode(leiCode: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (leiCode === null || leiCode === undefined) {
            throw new Error('Required parameter leiCode was null or undefined when calling getPartyByLeiCode.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (leiCode !== undefined && leiCode !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>leiCode, 'leiCode');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/api/v1/parties/_partyByLeiCode`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update ExtCashAccount table
     * Update existing ExtCashAccount table, locating target row through ID_BDR_PARTY and currency  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.extcashaccount.update; Authorities: SGM_ISS_PAY_api.issuer-ipa.extcashaccount.update
     * @param extCashAccountLevelDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateExtCashAccountTable(extCashAccountLevelDto: ExtCashAccountLevelDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any>;
    public updateExtCashAccountTable(extCashAccountLevelDto: ExtCashAccountLevelDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<any>>;
    public updateExtCashAccountTable(extCashAccountLevelDto: ExtCashAccountLevelDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<any>>;
    public updateExtCashAccountTable(extCashAccountLevelDto: ExtCashAccountLevelDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (extCashAccountLevelDto === null || extCashAccountLevelDto === undefined) {
            throw new Error('Required parameter extCashAccountLevelDto was null or undefined when calling updateExtCashAccountTable.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/v1/parties/updateExtCashAccount`,
            extCashAccountLevelDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

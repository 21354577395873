import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { InstrumentCriteriaSearchModel } from '@instruments/models/instrument-criteria-search.model';
import { InstrumentCriteriaSearchFormService } from '@instruments/services';
import { SearchInstrumentDisplayService } from '@instruments/services/display/search.display.service';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { ActiveFilterItem, TYPED_DATA_TYPE } from '@shared/models';
import { DisplayServiceInjectionToken, FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';
import { I18nUtilsService } from '@shared/services/i18n-utils.service';
import { InstrumentService } from '@shared/services/instrument.service';
import { downloadFile } from '@utils/utility-functions';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PIWIK_CONST } from 'src/app/core/analytics/piwik-tracking.constants';
import { Track } from '@sgwt/analytics-core';

@Component({
  selector: 'app-intrument-list-filter',
  templateUrl: './intrument-list-filter.component.html',
  styleUrls: ['./intrument-list-filter.component.scss'],
  providers: [
    { provide: FormServiceInjectionToken, useExisting: InstrumentCriteriaSearchFormService },
    { provide: DisplayServiceInjectionToken, useExisting: SearchInstrumentDisplayService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'instruments.list.filters' }
  ]
})
export class IntrumentListFilterComponent extends BaseFormComponent<InstrumentCriteriaSearchModel, InstrumentCriteriaSearchFormService> implements OnInit, OnDestroy {
  @Input() disableExportCsv = false;
  public INSTRUMENT_TYPE: TYPED_DATA_TYPE = 'INSTRUMENT_TYPE';
  public PAYMENT_TYPE: TYPED_DATA_TYPE = 'PAYMENT_TYPE';
  public SETTLEMENT_WORKFLOW_STATUS: TYPED_DATA_TYPE = 'SETTLEMENT_WORKFLOW_STATUS';
  public INSTRUMENT_WORKFLOW_STATUS: TYPED_DATA_TYPE = 'INSTRUMENT_WORKFLOW_STATUS';
  public TRANCHE_WORKFLOW_STATUS: TYPED_DATA_TYPE = 'TRANCHE_WORKFLOW_STATUS';
  public isCollapsed = true;
  public nullValue = null;
  private readonly shutdown$ = new Subject<void>();
  private readonly subscriptions: Subscription[] = [];

  public filters: ActiveFilterItem<InstrumentCriteriaSearchModel>[] = [];
  constructor(
    readonly InstrumentCriteriaSearchFormService: InstrumentCriteriaSearchFormService,
    public readonly i18nUtils: I18nUtilsService,
    private readonly instrumentService: InstrumentService,
    private readonly displayService: SearchInstrumentDisplayService
  ) {
    super(InstrumentCriteriaSearchFormService, 'instruments.list.filters');
  }

  ngOnInit(): void {
    const displayObservables = this.displayService.Compile();
    this.subscriptions.push(...displayObservables.map(o => o.subscribe()));
  }

  get beginDate(): number | null {
    const formDate = this.formService?.rawValue()?.beginIssuedate;
    if (formDate) {
      const date = formDate.split('-');
      const newDate = new Date(+date[2], +date[1] - 1, +date[0]);
      return newDate.getTime();
    }
    return null;
  }
  get endDate(): number | null {
    const formDate = this.formService?.rawValue()?.endIssuedate;
    if (formDate) {
      const date = formDate.split('-');
      const newDate = new Date(+date[2], +date[1] - 1, +date[0]);
      return newDate.getTime();
    }
    return null;
  }

  onDatesChanged(e: any): void {
    this.formService.patch('beginIssuedate', e?.detail?.from?.formatted);
    this.formService.control('beginIssuedate').markAsDirty();
    this.formService.patch('endIssuedate', e?.detail?.to?.formatted);
    this.formService.control('endIssuedate').markAsDirty();
  }

  @Track([PIWIK_CONST.methods.trackEvent, PIWIK_CONST.eventCategories.Instruments, PIWIK_CONST.eventActions.onDownloadClicked])
  exportCsv(): void {
    const criteria = this.formService.value();
    if (!criteria) {
      return;
    }
    this.instrumentService
      .exportInstrumentsData(criteria)
      .pipe(takeUntil(this.shutdown$))
      .subscribe(blob => {
        if (!blob) {
          return;
        }
        downloadFile(blob, 'Instruments.csv');
      });
  }
  public get canExport(): boolean {
    return Object.keys(this.formService.fields)
      .map((_, index, array) => {
        const item = array[index];
        return this.formService.control(item as keyof InstrumentCriteriaSearchModel);
      })
      .some(q => q.value !== null && q.value !== undefined);
  }
  public onFiltersChanged(filters: ActiveFilterItem<InstrumentCriteriaSearchModel>[]): void {
    this.filters = filters;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.shutdown$.next();
    this.shutdown$.complete();
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationStart } from '@angular/router';
import { environment } from '@environment/environment';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { PIWIK_CONST } from './piwik-tracking.constants';
import { SGWTConnect } from '@sgwt/connect-angular';
import { Location } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
export class PiwikTrackingService implements OnDestroy {
  private routerEventsSubscription: Subscription | undefined;

  constructor(private readonly router: Router, private readonly title: Title, private readonly location: Location) {}

  public configurePiwikTracker(): void {
    const wrapper = document.querySelector('sgwt-connect') as ISgwtConnectElement;

    const paq: (string | number | boolean)[][] = window._paq || [];
    const miniFooter = document.querySelector('sgwt-mini-footer');
    //following the documentation https://developer.sgmarkets.com/docs/web/widgets/structural-widgets/sgwt-mini-footer.html
    // using  the  getUserConsentOnMatomo
    // when consent change
    (miniFooter as any)?.getUserConsentOnMatomo().then((consent: boolean) => {
      paq.push(['requireCookieConsent']);
      if (consent) {
        paq.push(['rememberCookieConsentGiven', 4320]);
        paq.push(['setCustomDimension', 10, true]);
      } else {
        paq.push(['forgetCookieConsentGiven']);
        paq.push(['setCustomDimension', 10, false]);
      }
      paq.push(['enableLinkTracking']);
      paq.push(['setTrackerUrl', environment.sgsEnvProperties.matomo_url + PIWIK_CONST.config.trakcerUrl]);
      paq.push(['setSiteId', environment.sgsEnvProperties.matomo_siteId]);
      (window as { _paq: any })._paq = paq;
      this.addPiwikScriptReference();
      this.listenCookiesUserAgreement(miniFooter);
      if (wrapper) {
        const sgwtConnect = wrapper.sgwtConnect;
        if (sgwtConnect) {
          const claims = sgwtConnect.getIdTokenClaims();
          window._paq.push(['setUserId', claims ? claims.sub : '']);
        }
      }
      if (environment.production) {
        this.enableRouteNavigationTracking();
      }
    });
  }
  private listenCookiesUserAgreement(miniFooter: any): void {
    miniFooter.addEventListener('sgwt-mini-footer--user-consent-changed', () => {
      miniFooter.getUserConsentOnMatomo().then((consent: boolean) => this.updateMatomoUserAcceptance(consent));
    });
  }
  private updateMatomoUserAcceptance(consent: any) {
    if (consent) {
      (window as any)._paq.push(['rememberCookieConsentGiven', 4320]); // 4320 = retention period of the cookie for 6 month
      (window as any)._paq.push(['setCustomDimension', 10, true]);
    } else {
      (window as any)._paq.push(['forgetCookieConsentGiven']);
      (window as any)._paq.push(['setCustomDimension', 10, false]);
    }
  }
  public enableRouteNavigationTracking(): void {
    // Track the Angular Route change
    this.routerEventsSubscription = this.router.events.pipe(filter(event => event instanceof NavigationStart && event.url.split('?')[0] === this.router.url.split('?')[0])).subscribe(() => {
      window._paq.push(['setDocumentTitle', this.title.getTitle()]);
      window._paq.push(['setCustomUrl', this.location.path()]);
      window._paq.push([PIWIK_CONST.methods.trackPageView]);
    });
  }

  private addPiwikScriptReference() {
    const pa = document.createElement('script');
    pa.type = 'text/javascript';
    pa.async = true;
    pa.src = environment.sgsEnvProperties.matomo_url + PIWIK_CONST.config.scriptFileName;
    const s = document.getElementsByTagName('script')[0];
    s.parentNode?.insertBefore(pa, s);
  }

  public ngOnDestroy(): void {
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }
}

export interface ISgwtConnectElement extends HTMLElement {
  sgwtConnect: SGWTConnect;
}

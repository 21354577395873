import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { ValuationDetailFormService } from '@valuation/services';
import { routeToValuation } from '@valuation/routes';
import { ValuationService } from '@shared/services/valuation.service';
import { ValuationModalsService } from '@valuation/services/valuation-modals.service';
import { PIWIK_CONST } from 'src/app/core/analytics/piwik-tracking.constants';
import { Track } from '@sgwt/analytics-core';

@Component({
  selector: 'app-valuation-save-cancel',
  templateUrl: './valuation-save-cancel.component.html',
  providers: [ValuationModalsService]
})
export class ValuationSaveCancelComponent {
  constructor(
    private readonly valuationDetailFormService: ValuationDetailFormService,
    private readonly valuationService: ValuationService,
    private readonly modalsService: ValuationModalsService,
    private readonly toastService: ToastManagerService,
    private readonly router: Router
  ) {}

  @Track([PIWIK_CONST.methods.trackEvent, PIWIK_CONST.eventCategories.Valuation, PIWIK_CONST.eventActions.onSaveClicked])
  onSaveClick() {
    this.valuationDetailFormService.updateValueAndValidity();
    const valuationStatus = this.valuationDetailFormService.value('valuationStatus');
    const valuationRef = this.valuationDetailFormService.value('valuationRef');
    const remarks = this.valuationDetailFormService.value('remarks') || undefined;
    if (!valuationStatus) {
      this.toastService.toastWarning('toasts.instruments.invalid-form.title', 'toasts.instruments.invalid-form.message');
      return;
    }

    if (this.valuationDetailFormService.valid && valuationRef) {
      const modal = this.modalsService.openSaveModal();
      modal.result.then((res: DialogResult) => {
        if (res !== 'confirm') {
          return;
        }
        this.saveValuation(valuationRef, valuationStatus, remarks);
      });
    }
  }

  onCancelClick() {
    if (this.valuationDetailFormService.dirty) {
      const modal = this.modalsService.openCancelModal();
      modal.result.then((res: DialogResult) => {
        if (res !== 'confirm') {
          return;
        }

        this.goBack();
      });
      return;
    }
    this.goBack();
  }

  saveValuation(valuationRef: number, valuationStatus?: string | undefined, remarks?: string | undefined) {
    //piwik Event Tracking
    (window as any)._paq.push(['trackEvent', PIWIK_CONST.eventCategories.Valuation, PIWIK_CONST.eventActions.updateValuation]);
    this.valuationService.updateValuationStatus(valuationRef, valuationStatus, remarks).subscribe(response => {
      if (response) {
        this.goBack();
      }
    });
  }

  @Track([PIWIK_CONST.methods.trackEvent, PIWIK_CONST.eventCategories.Valuation, PIWIK_CONST.eventActions.onBackClicked])
  private goBack() {
    const valuationRef = this.valuationDetailFormService.value('valuationRef');
    this.router.navigate(routeToValuation(valuationRef, 'consult'));
  }
}

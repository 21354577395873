/*  eslint-disable */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EventsService } from '@shared/services/events.service';
import { EventCriteriaSearchFormService, EventModalsService, EventFormService } from '@events/services';
import { InstrumentService } from '@shared/services/instrument.service';
import { EventCriteriaSearchModel, toScheduleSearchInputModel, ScheduleEventGridViewModel, toScheduleEventGridViewModel } from '@events/models';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { InstrumentResourceService } from '@apis/backend/instruments';
import { PermissionService } from '@shared/services/permission.service';
import { Router, ActivatedRoute } from '@angular/router';
import { EventSearchService, EventSearchQueryModel } from '@events/services/eventSearchService';
import { RegenerateSchedulesFormService } from '@events/services/regenerateSchedules.form.service';
import { ScheduleEvent, EventDetails, EventInstrumentDataDto } from '@apis/backend/events';
import { EventsListComponent } from '..';
import { EVENT_CONSTANTS } from '@events/configs/event-constants';
import { InstrumentModel } from '@instruments/models';
import { downloadFile } from '@utils/utility-functions';
import { formatDate } from '@angular/common';
import { PIWIK_CONST } from 'src/app/core/analytics/piwik-tracking.constants';
import { Track } from '@sgwt/analytics-core';

@Component({
  selector: 'app-events-container',
  templateUrl: './events-container.component.html',
  styleUrls: ['./events-container.component.scss'],
  providers: [EventCriteriaSearchFormService, EventModalsService, EventSearchService]
})
export class EventsContainerComponent implements OnInit, OnDestroy {
  private readonly shutdown$ = new Subject<void>();
  private searchCriteria: EventCriteriaSearchModel | null | undefined;
  public scheduledEvents: ScheduleEventGridViewModel[] = [];
  private selectedIsin = '';
  public eventIdList: number[] | undefined;
  public loading = false;
  public disableSearch = false;
  public displayPagination = false;
  private readonly internationalISIN = [EVENT_CONSTANTS.leiClearstreamBankingSALuxembourg, EVENT_CONSTANTS.leiEuroclearBankBelgium];
  isInternationalISIN = false;
  loaded?: Promise<boolean>;
  itemsByPage = 50;
  pageNumber = 1;
  totalItems = 0;
  @ViewChild(EventsListComponent) eventsListComponent!: EventsListComponent;
  canCreateEvents = false;
  constructor(
    private readonly eventService: EventsService,
    private readonly instrumentResourceService: InstrumentResourceService,
    private readonly eventModalsService: EventModalsService,
    public eventCriteriaSearchFormService: EventCriteriaSearchFormService,
    public eventFormService: EventFormService,
    public regenerateSchedulesFormService: RegenerateSchedulesFormService,
    public instrumentService: InstrumentService,
    public toastManagerService: ToastManagerService,
    public permissionService: PermissionService,
    private readonly eventSearchService: EventSearchService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.permissionService.canCreateEvents$.pipe(takeUntil(this.shutdown$)).subscribe({
      next: (canCreateEvents: boolean) => {
        this.canCreateEvents = canCreateEvents;
      }
    });
    const queryString = this.activatedRoute.snapshot.queryParams.query;
    if (queryString) {
      const query = JSON.parse(queryString) as EventSearchQueryModel;
      this.eventSearchService.setInitialValue(query);
    }
    this.subscribeToFormChanges();
    if (queryString !== undefined && queryString !== '{}') {
      this.search();
    }
  }

  search(): void {
    this.pageNumber = 1;
    this.searchCriteria = this.eventCriteriaSearchFormService.value();
    if (
      this.searchCriteria?.ipaCode ||
      this.searchCriteria?.isin ||
      this.searchCriteria?.eventId ||
      this.searchCriteria?.eventStatuses ||
      (this.searchCriteria?.notificationFromDate && this.searchCriteria?.notificationToDate) ||
      (this.searchCriteria?.preAdviceFromDate && this.searchCriteria?.preAdviceToDate) ||
      (this.searchCriteria?.reqFundFromDate && this.searchCriteria?.reqFundToDate) ||
      (this.searchCriteria?.valueFromDate && this.searchCriteria?.valueToDate)
    ) {
      this.loadEvents();
    } else if (this.searchCriteria?.eventTypes) {
      if (
        this.searchCriteria?.eventStatuses ||
        (this.searchCriteria?.notificationFromDate && this.searchCriteria?.notificationToDate) ||
        (this.searchCriteria?.preAdviceFromDate && this.searchCriteria?.preAdviceToDate) ||
        (this.searchCriteria?.reqFundFromDate && this.searchCriteria?.reqFundToDate) ||
        (this.searchCriteria?.valueFromDate && this.searchCriteria?.valueToDate)
      ) {
        this.loadEvents();
        return;
      }
      this.toastManagerService.toastError('toasts.events.mandatoryDateSearchCriteria.title', 'toasts.events.mandatoryDateSearchCriteria.message');
    } else {
      this.toastManagerService.toastError('toasts.events.searchCriteria.title', 'toasts.events.searchCriteria.message');
    }
  }

  private subscribeToFormChanges(): void {
    const Allkeys = this.eventSearchService.getfields();
    this.eventSearchService.valueChanges(Allkeys).subscribe(q => {
      const query = this.removeEmpty(q);
      const stringfiedPredicate = JSON.stringify(query);
      this.router.navigate(['/events'], { queryParams: { query: stringfiedPredicate } });
    });
  }
  onCreateEventClick(): void {
    const modal = this.eventModalsService.openCreateEventModal(this.selectedIsin);
    modal.result.then(
      (res: DialogResult) => {
        if (res !== 'confirm') {
          this.selectedIsin = '';
          return;
        }
        this.createScheduleEvent();
      },
      () => {
        this.selectedIsin = '';
      }
    );
  }

  onRegenerateSchedulesClick(): void {
    const modal = this.eventModalsService.openRegenerateSchedulesModal(this.selectedIsin);
    modal.result.then(
      (res: DialogResult) => {
        if (res !== 'confirm') {
          this.selectedIsin = '';
          return;
        }
        const enteredIsin = this.regenerateSchedulesFormService?.rawValue()?.isin;
        if (enteredIsin) {
          this.selectedIsin = enteredIsin;
          this.loading = true;
          this.fetchInstruments();
        }
      },
      () => {
        this.selectedIsin = '';
      }
    );
  }

  onPageChanged(selectedPageNumber: any) {
    this.pageNumber = selectedPageNumber;
    this.loadEvents();
  }

  @Track([PIWIK_CONST.methods.trackEvent, PIWIK_CONST.eventCategories.Events,PIWIK_CONST.eventActions.onRegenrateClicked])
  private fetchInstruments() {
    this.instrumentResourceService.getInstrumentBySingleIsin(this.selectedIsin).subscribe(result => {
      if (result) {
        const searchCriteria = {
          couponFrequency: result.instrumentEventsData?.couponFrequency?.valueId,
          couponType: result.instrumentEventsData?.couponType?.valueDescription,
          ipaCode: result.id
        };
        this.eventService.regenerateSchedules(searchCriteria).subscribe(result => {
          if (result && result.schedules) {
            const message = ' ';
            this.toastManagerService.toastSuccess('toasts.events.scheduleRegenerate.title', message);
          }
          this.loading = false;
        });
      }
    });
  }

  @Track([PIWIK_CONST.methods.trackEvent, PIWIK_CONST.eventCategories.Events,PIWIK_CONST.eventActions.onCreateEventClicked])
  private createScheduleEvent() {
    this.loading = true;
    const scheduleEventModel = this.eventFormService.value();
    if (scheduleEventModel) {
      this.instrumentService.getInstrumentByIsin(scheduleEventModel?.isin).subscribe(
        response => {
          if (response?.id) {
            const clearingSystem = response.clearingSystems ? response.clearingSystems.map(x => x.leiCode) : undefined;
            this.isInternationalISIN = this.internationalISIN.filter(x => clearingSystem?.includes(x)).length > 0;
            this.eventService.createEvent(scheduleEventModel, response?.id, this.isInternationalISIN).subscribe(result => {
              if (result && result.eventDetails) {
                const message = `Event Reference Id: ${result.eventDetails[0].eventReference?.toString()} `;
                this.toastManagerService.toastSuccess('toasts.events.create.title', message, undefined, result);
              }
              this.loading = false;
            });
          } else {
            this.toastManagerService.toastError('toasts.events.ipaMissing.title', 'toasts.events.ipaMissing.message', undefined, response);
            this.loading = false;
          }
        },
        error => {
          if (error) {
            this.loading = false;
          }
        }
      );
    }
  }

  selectedISIN(isin: string) {
    this.selectedIsin = isin;
  }

  private loadEvents() {
    this.disableSearch = true;
    if (this.searchCriteria?.isin !== null && this.searchCriteria?.isin?.length !== 0) {
      let ipaCode: number[];
      this.instrumentService.getInstrumentByIsin(this.searchCriteria?.isin).subscribe(res => {
        if (res?.id && Number(this.searchCriteria?.ipaCode)) {
          if (res.id === Number(this.searchCriteria?.ipaCode)) {
            ipaCode = [res?.id];
            this.loadEventsData(ipaCode);
          } else {
            this.displayNotFoundToastMessage(res);
            this.disableSearch = false;
          }
        } else if (res?.id) {
          ipaCode = [res?.id];
          this.loadEventsData(ipaCode);
        } else {
          this.displayNotFoundToastMessage(res);
          this.disableSearch = false;
        }
      });
    } else {
      this.loadEventsData();
    }
    this.scheduledEvents = [];
  }

  private displayNotFoundToastMessage(response: InstrumentModel | null) {
    this.toastManagerService.toastError('toasts.events.notFound.title', 'toasts.events.notFound.message', undefined, response);
    this.scheduledEvents = [];
  }

  private loadEventsData(ipaCodes?: number[]) {
    const scheduleSearchInputModel = this.searchCriteria ? toScheduleSearchInputModel(this.searchCriteria, ipaCodes, this.pageNumber) : null;
    const rowData: ScheduleEventGridViewModel[] = [];
    const resultArrayList: ScheduleEvent[] = [];
    let instrumentData: EventInstrumentDataDto | undefined;
    let scheduleData: ScheduleEvent | undefined;
    let eventData: EventDetails | undefined;
    this.eventService
      .getAllEventsPaged(scheduleSearchInputModel ?? {}, this.itemsByPage)
      .pipe(takeUntil(this.shutdown$))
      .subscribe(
        result => {
          if (result) {
            if (result.totalItems === 0 && result.title) {
              this.toastManagerService.toastError(result.title!, 'toasts.events.notFound.message');
              this.disableSearch = false;
              return;
            }
            if (result && result.scheduleMap && result.eventInstrumentMap) {
              const keyValues = Object.keys(result.scheduleMap);
              keyValues?.forEach(x => {
                scheduleData = result.scheduleMap ? result.scheduleMap[x] : {};
                resultArrayList.push(scheduleData);
              });
              this.totalItems = result.totalItems!;
              resultArrayList.forEach(schedule => {
                eventData = result.eventMap && schedule.eventReference ? result.eventMap[schedule.eventReference] : undefined;
                instrumentData = result.eventInstrumentMap && schedule.instrumentId ? result.eventInstrumentMap[schedule.instrumentId] : undefined;
                rowData.push(toScheduleEventGridViewModel(instrumentData, schedule, eventData)!);
                this.scheduledEvents = rowData;
                this.disableSearch = false;
                this.displayPagination = true;
                this.loaded = Promise.resolve(true);
              });
            } else {
              this.disableSearch = false;
              this.scheduledEvents = [];
              this.loaded = Promise.resolve(true);
              return;
            }
          }
        },
        error => {
          this.disableSearch = false;
          if (error.status === 404) {
            this.scheduledEvents = [];
            this.loaded = Promise.resolve(true);
          }
        }
      );
  }

  @Track([PIWIK_CONST.methods.trackEvent, PIWIK_CONST.eventCategories.Events,PIWIK_CONST.eventActions.onDownloadClicked])
  public exportCSV() {
    this.disableSearch = true;
    let ipaCode: number[] = [];
    if (this.searchCriteria?.isin !== null && this.searchCriteria?.isin?.length !== 0) {
      this.instrumentService.getInstrumentByIsin(this.searchCriteria?.isin).subscribe(res => {
        if (res && res.id) {
          if (Number(this.searchCriteria?.ipaCode) && res.id === Number(this.searchCriteria?.ipaCode)) {
            ipaCode = [res.id];
          } else {
            ipaCode = [res.id];
          }
        }
      });
    }
    const scheduleSearchInputModel = this.searchCriteria ? toScheduleSearchInputModel(this.searchCriteria, ipaCode) : null;
    this.eventService
      .exportEventsData(scheduleSearchInputModel!)
      .pipe(takeUntil(this.shutdown$))
      .subscribe(
        blob => {
          if (!blob) {
            return;
          }
          const date = formatDate(new Date(), 'yyyy_MM_dd', 'en');
          downloadFile(blob, `${date}_events` + `.csv`);
          this.disableSearch = false;
        },
        error => {
          if (error) this.disableSearch = false;
        }
      );
  }

  public getEventsReport() {
    this.disableSearch = true;
    this.eventService
      .generateEventsReport()
      .pipe(takeUntil(this.shutdown$))
      .subscribe(
        blob => {
          if (!blob) {
            return;
          }
          const date = formatDate(new Date(), 'yyyy_MM_dd', 'en');
          downloadFile(blob, `${date}_EventsReport` + '.csv');
          this.disableSearch = false;
        },
        error => {
          if (error) this.disableSearch = false;
        }
      );
  }
  
  removeEmpty(obj: any) {
    return Object.entries(obj)
      .filter(([_, v]) => v != null)
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }

  toDateFormat = (dateString: string): string => {
    const [date] = dateString.split('T');
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  };

  ngOnDestroy(): void {
    this.shutdown$.next();
  }
}

<div class="section-forms" [formGroup]="formGroup">
    <div class="row">
        <div class="form-group  col-sm-6">
            <app-form-item [field]="fields.idBdrParty" [focus]="idBdrParty">
                <input class="form-control" type="text" [formControlName]="fields.idBdrParty"
                    [placeholder]="placeholder(fields.idBdrParty) | translate" component #idBdrParty />
            </app-form-item>
        </div>
        <div class="form-group  col-sm-6">
            <app-form-item [field]="fields.currency" [focus]="currency">
                <input class="form-control" type="text" [formControlName]="fields.currency"
                    [placeholder]="placeholder(fields.currency) | translate" component #currency />
            </app-form-item>
        </div>
        <div *ngIf="modify">
            <div class="col-sm-12 pt-1">
                <app-text-icon-button icon="search" (clicked)="onSearch()" label="{{'payment.list.search'|translate}}" [submit]="true"
                size="md" customClasses="d-flex ms-auto btn-icon-start" buttonType="btn-outline-default"></app-text-icon-button>
            </div>
        </div>
    </div>
    <div *ngIf="!modify" class="row">
        <div class="form-group  col-sm-6">
            <app-form-item [field]="fields.correspondBankName" [focus]="correspondBankName">
                <input class="form-control" type="text" [formControlName]="fields.correspondBankName"
                    [placeholder]="placeholder(fields.correspondBankName) | translate" component #correspondBankName />
            </app-form-item>
        </div>
        <div class="form-group  col-sm-6">
            <app-form-item [field]="fields.correspondBankBIC" [focus]="correspondBankBIC">
                <input class="form-control" type="text" [formControlName]="fields.correspondBankBIC"
                    [placeholder]="placeholder(fields.correspondBankBIC) | translate" component #correspondBankBIC />
            </app-form-item>
        </div>
    </div>
    <div *ngIf="!modify" class="row">
        <div class="form-group  col-sm-6">
            <app-form-item [field]="fields.intermediaryName" [focus]="intermediaryName">
                <input class="form-control" type="text" [formControlName]="fields.intermediaryName"
                    [placeholder]="placeholder(fields.intermediaryName) | translate" component #intermediaryName />
            </app-form-item>
        </div>
        <div class="form-group  col-sm-6">
            <app-form-item [field]="fields.intermediaryBIC" [focus]="intermediaryBIC">
                <input class="form-control" type="text" [formControlName]="fields.intermediaryBIC"
                    [placeholder]="placeholder(fields.intermediaryBIC) | translate" component #intermediaryBIC />
            </app-form-item>
        </div>
    </div>
    <div *ngIf="!modify" class="row">
        <div class="form-group  col-sm-6">
            <app-form-item [field]="fields.account" [focus]="account">
                <input class="form-control" type="text" [formControlName]="fields.account"
                    [placeholder]="placeholder(fields.account) | translate" component #account />
            </app-form-item>
        </div>
        <div class="form-group  col-sm-6">
            <app-form-item [field]="fields.internalAccountName" [focus]="internalAccountName">
                <input class="form-control" type="text" [formControlName]="fields.internalAccountName"
                    [placeholder]="placeholder(fields.internalAccountName) | translate" component #internalAccountName />
            </app-form-item>
        </div>
    </div>
    <div *ngIf="!modify" class="row">
        <div class="form-group  col-sm-6">
            <app-form-item [field]="fields.correspondBankAccount" [focus]="correspondBankAccount">
                <input class="form-control" type="text" [formControlName]="fields.correspondBankAccount"
                    [placeholder]="placeholder(fields.correspondBankAccount) | translate" component #correspondBankAccount />
            </app-form-item>
        </div>
        <div class="form-group  col-sm-6">
            <app-form-item [field]="fields.accountBIC" [focus]="accountBIC">
                <input class="form-control" type="text" [formControlName]="fields.accountBIC"
                    [placeholder]="placeholder(fields.accountBIC) | translate" component #accountBIC />
            </app-form-item>
        </div>
    </div>
</div>



import { Component, OnInit, ViewChild } from '@angular/core';
import { PaymentCriteriaSearchFormService } from '../../services';
import { PaymentModel, toPaymentModel, PaymentCriteriaSearchModel } from '../../models';
import { PaymentService } from '@shared/services/payment.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentSearchQueryModel, PaymentSearchService } from '../../services/paymentSearchService';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { PaymentListComponent } from '../payment-list/payment-list.component';
import { downloadFile } from '@utils/utility-functions';
import { formatDate } from '@angular/common';
import { PIWIK_CONST } from 'src/app/core/analytics/piwik-tracking.constants';
import { Track } from '@sgwt/analytics-core';

@Component({
  selector: 'app-payment-container',
  templateUrl: './payment-container.component.html',
  styleUrls: ['./payment-container.component.scss'],
  providers: [PaymentCriteriaSearchFormService, PaymentService, PaymentSearchService]
})
export class PaymentContainerComponent implements OnInit {
  @ViewChild(PaymentListComponent) paymentListComponent!: PaymentListComponent;
  private readonly shutdown$ = new Subject<void>();
  public payments: PaymentModel[] = [];
  private paymentSearchCriteria: PaymentCriteriaSearchModel | null | undefined;
  private readonly mandatorySearchCriteriaTitle = 'toasts.payment.mandatorySearchCriteria.title';
  public disableSearch = false;
  public displayPagination = false;
  loaded?: Promise<boolean>;
  itemsByPage = 50;
  pageNumber = 1;
  totalItems = 0;

  constructor(
    private readonly paymentService: PaymentService,
    private readonly paymentCriteriaSearchFormService: PaymentCriteriaSearchFormService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly paymentSearchService: PaymentSearchService,
    public toastManagerService: ToastManagerService,
    private readonly router: Router
  ) {}

  ngOnInit() {
    const queryString = this.activatedRoute.snapshot.queryParams.query;
    if (queryString) {
      const query = JSON.parse(queryString) as PaymentSearchQueryModel;
      this.paymentSearchService.setInitialValue(query);
    }
    this.subscribeToFormChanges();
    if (queryString !== undefined && queryString !== '{}') {
      this.search();
    }
  }

  private subscribeToFormChanges(): void {
    const Allkeys = this.paymentSearchService.getfields();
    this.paymentSearchService.valueChanges(Allkeys).subscribe(q => {
      const query = this.removeEmpty(q);
      const stringfiedPredicate = JSON.stringify(query);
      this.router.navigate(['/payment'], { queryParams: { query: stringfiedPredicate } });
    });
  }

  search() {
    this.pageNumber = 1;
    this.paymentCriteriaSearchFormService.updateValueAndValidity();
    if (this.paymentCriteriaSearchFormService.isFormValid) {
      if (this.paymentCriteriaSearchFormService.rawValue('maxAmount') && !this.paymentCriteriaSearchFormService.rawValue('minAmount')) {
        this.paymentCriteriaSearchFormService.patchField('minAmount', 0);
      }
      this.paymentSearchCriteria = this.paymentCriteriaSearchFormService.value();
      if (this.paymentSearchCriteria?.isin || this.paymentSearchCriteria?.fundingRequestRef) {
        this.loadPayments();
      } else if (this.paymentSearchCriteria?.currency) {
        if (this.paymentSearchCriteria?.eventTypes || this.paymentSearchCriteria?.paymentStatus) {
          this.loadPayments();
        } else {
          this.toastManagerService.toastError(this.mandatorySearchCriteriaTitle, 'toasts.payment.mandatoryPaymentStatusEventTypeSearchCriteria.message');
        }
      } else if (this.paymentSearchCriteria?.minAmount || this.paymentSearchCriteria?.maxAmount) {
        if (this.paymentSearchCriteria?.eventTypes && ((this.paymentSearchCriteria?.valueFromDate && this.paymentSearchCriteria?.valueToDate) || this.paymentSearchCriteria?.paymentStatus)) {
          this.loadPayments();
        } else {
          this.toastManagerService.toastError(this.mandatorySearchCriteriaTitle, 'toasts.payment.mandatoryPaymentStatusValueDateEventTypeSearchCriteria.message');
        }
      } else if (this.paymentSearchCriteria?.paymentStatus) {
        if ((this.paymentSearchCriteria?.valueFromDate && this.paymentSearchCriteria?.valueToDate) || this.paymentSearchCriteria?.eventTypes || this.paymentSearchCriteria?.paymentStatus[0].valueId === '7') {
          this.loadPayments();
        } else {
          this.toastManagerService.toastError(this.mandatorySearchCriteriaTitle, 'toasts.payment.mandatoryEventTypeValueDateSearchCriteria.message');
        }
      } else if (this.paymentSearchCriteria?.eventTypes) {
        if ((this.paymentSearchCriteria?.valueFromDate && this.paymentSearchCriteria?.valueToDate) || this.paymentSearchCriteria?.paymentStatus) {
          this.loadPayments();
        } else {
          this.toastManagerService.toastError(this.mandatorySearchCriteriaTitle, 'toasts.payment.mandatoryPaymentStatusValueDateSearchCriteria.message');
        }
      } else if (this.paymentSearchCriteria?.valueFromDate && this.paymentSearchCriteria?.valueToDate) {
        if (this.paymentSearchCriteria?.eventTypes || this.paymentSearchCriteria?.paymentStatus) {
          this.loadPayments();
        } else {
          this.toastManagerService.toastError(this.mandatorySearchCriteriaTitle, 'toasts.payment.mandatoryPaymentStatusEventTypeSearchCriteria.message');
        }
      } else {
        this.toastManagerService.toastError(this.mandatorySearchCriteriaTitle, 'toasts.payment.mandatorySearchCriteria.message');
      }
    }
  }

  private loadPayments() {
    this.disableSearch = true;
    const rowData: PaymentModel[] = [];
    this.paymentService
      .getPayments(this.paymentSearchCriteria ?? {}, this.pageNumber, this.itemsByPage)
      .pipe(takeUntil(this.shutdown$))
      .subscribe(result => {
        if (result?.dtos) {
          result.dtos?.forEach(payment => {
            const paymentData = toPaymentModel(payment);
            rowData.push(paymentData);
            this.payments = rowData;
            this.totalItems = result.totalItems!;
            this.displayPagination = true;
            this.disableSearch = false;
            this.loaded = Promise.resolve(true);
          });
        } else {
          this.toastManagerService.toastError('toasts.payment.notFound.title', 'toasts.payment.notFound.message', undefined, result);
          this.payments = [];
          this.disableSearch = false;
          this.loaded = Promise.resolve(true);
        }
      });
  }

  onPageChanged(selectedPageNumber: any) {
    this.pageNumber = selectedPageNumber;
    this.loadPayments();
  }

  @Track([PIWIK_CONST.methods.trackEvent, PIWIK_CONST.eventCategories.Payment, PIWIK_CONST.eventActions.onDownloadClicked])
  public exportCsv() {
    this.disableSearch = true;
    this.paymentService
      .exportPaymentsData(this.paymentSearchCriteria!)
      .pipe(takeUntil(this.shutdown$))
      .subscribe(
        blob => {
          if (!blob) {
            return;
          }
          const date = formatDate(new Date(), 'yyyy_MM_dd', 'en');
          downloadFile(blob, `${date}_payment` + '.csv');
          this.disableSearch = false;
        },
        error => {
          if (error) this.disableSearch = false;
        }
      );
  }

  removeEmpty(obj: any) {
    return Object.entries(obj)
      .filter(([_, v]) => v != null)
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }
}

import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { ExtCashAccountModel, toExtCashAccountModel } from '../model/extCashAccountModel';
import { InstructionFormService } from '../services/instruction-form.service';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';
import { InstructionService } from '../services/instruction.service';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { Validators } from '@angular/forms';

@Component({
  selector: 'app-ecash-account',
  templateUrl: './ecashAccount-create.component.html',
  providers: [
    { provide: FormServiceInjectionToken, useExisting: InstructionFormService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'instructions.creation' }
  ]
})
export class ECashAccountComponent extends BaseFormComponent<ExtCashAccountModel, InstructionFormService> {
  @Input() modify = false;
  @Output() displayConfirm = new EventEmitter<boolean>();
  public disbaleOnModify = false;

  constructor(private readonly instructionFormService: InstructionFormService, private readonly instructionService: InstructionService, private readonly toastManagerService: ToastManagerService) {
    super(instructionFormService, 'instructions.form');
    const internalAccountName = this.formGroup.get('internalAccountName');
    internalAccountName?.setValidators(Validators.required);
    this.instructionFormService.unlockFields('internalAccountName');
  }

  public onSearch() {
    const internalAccountName = this.formGroup.get('internalAccountName');
    internalAccountName?.clearValidators();
    this.instructionFormService.updateValueAndValidity();
    if (this.instructionFormService.valid) {
      const data = this.instructionFormService.rawValue();
      this.instructionService.getSSIDetails(data.currency!, data.idBdrParty!).subscribe(result => {
        if (result && result.resources && result.resources.length > 0) {
          const value = result.resources[0];
          this.modify = false;
          this.instructionFormService.setInitialValue(toExtCashAccountModel(value));
          this.instructionFormService.lockFields('internalAccountName');
          this.displayConfirm.emit(true);
        } else {
          const message = ' ';
          this.toastManagerService.toastError('toasts.instructions.ssiNotFound.title', message);
        }
      });
    }
  }
}

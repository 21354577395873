import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstructionShellComponent } from './instruction-shell/instruction-shell.component';
import { instructionRoutes as InstructionRoutes } from './instructions.routing';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { HeaderModule } from '@shared/modules/header/header.module';
import { TranslateModule } from '@ngx-translate/core';
import { InstructionsContainerComponent } from './instructions-container/instructions-container.component';
import { InstructionsListComponent } from './instructions-list/instructions-list.component';
import { AgGridModule } from 'ag-grid-angular';
import { InstructionFormComponent } from './instruction-form/instruction-form.component';
import { ECashAccountComponent } from './eCashAccount-create/ecashAccount-create.component';
import { LocalCustomFormModule } from '@shared/modules/forms/local-custom-form.module';
import { SSIInstrumentPartyFormComponent } from './ssi-instrument-party-form/ssi-instrument-party-form.component';
import { ButtonsModule } from '@shared/modules/buttons/buttons.module';

@NgModule({
  declarations: [InstructionShellComponent, InstructionsContainerComponent, InstructionsListComponent, ECashAccountComponent, InstructionFormComponent, SSIInstrumentPartyFormComponent],
  imports: [CommonModule, InstructionRoutes, SharedModule.forRoot(), HeaderModule, TranslateModule, AgGridModule, ReactiveFormsModule, LocalCustomFormModule, FormsModule, ButtonsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class InstructionsModule {
  static forRoot(): ModuleWithProviders<InstructionsModule> {
    return {
      ngModule: InstructionsModule
    };
  }
}

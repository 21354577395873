import { Injectable } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FormService } from '@shared/services/forms/form.service';
import { SSIPartyModel } from '../model/ssiParty.model';

@Injectable({
  providedIn: 'root'
})
export class PartyFormService extends FormService<SSIPartyModel> {
  constructor(fb: UntypedFormBuilder) {
    super('party', fb, {
      leiCode: fb.control(null, [Validators.required]),
      idBdrParty: fb.control(null, [Validators.required]),
      description: fb.control(null, [Validators.required])
    });
  }
}

<div class="modal-header">
  <h3 class="modal-title" translate>modals.titles.settlement-history</h3>
  <button type="button" class="close icon p-1" (click)="onClose()">close</button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <div class="timeline timeline-vertical mt-1" *ngIf="settlement">
        <div *ngFor="let item of settlement?.settlementStatusHistory" class="timeline-item w-100 pb-5">
          <span class="timeline-day">{{ item.creationDateAsString | date:'YYYY':'UTC' }}</span>
          <div class="timeline-date">
            <span class="display-5">{{ item.creationDateAsString | date: 'dd MMM':'UTC' }}</span>
            <span class="display-6"> {{ item.creationDateAsString | timeFormat:'UTC' }}</span>
          </div>
          <div class="timeline-date">
            <span *ngIf="item.status" class="badge-discreet-secondary">{{ item.status }}</span>
          </div>
          <div class="timeline-date"  *ngIf="item.settlementDateHistory">
            <span class="display-5">{{ item.settlementDateHistory | date: 'dd MMM':'UTC' }}</span>
            <span class="display-6"> {{ item.settlementDateHistory | timeFormat:'UTC' }} - </span>
            <span class="display-6" translate>modals.titles.settlement-history-date</span>
          </div> 
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" (click)="onCancel()" (keyup.enter)="onCancel()" class="btn btn-lg btn-link">{{ closeButton | translate }}</button>
</div>

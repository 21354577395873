import { Observable, forkJoin, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ValuationService } from '@shared/services/valuation.service';
import { EventsService } from '@shared/services/events.service';
import { ValuationDetailModel } from './models';
import { EventDetailModel } from '@events/models';

@Injectable({
  providedIn: 'root'
})
export class ValuationResolve implements Resolve<[ValuationDetailModel | null, EventDetailModel | null]> {
  constructor(private readonly service: ValuationService, private readonly eventService: EventsService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<[ValuationDetailModel | null, EventDetailModel | null]> {
    const id = route.params['id'];

    return this.service.getValuationDetail(Number(id), true).pipe(
      catchError(() => of(null)),
      mergeMap(valuation => {
        if (valuation && valuation.eventId) {
          return forkJoin({
            valuation: of(valuation),
            event: this.eventService.getCompleteEventDetails(Number(valuation.eventId)).pipe(catchError(() => of(null)))
          }).pipe(map(result => [result.valuation, result.event] as [ValuationDetailModel | null, EventDetailModel | null]));
        } else {
          return of([valuation, null] as [ValuationDetailModel | null, EventDetailModel | null]);
        }
      })
    );
  }
}

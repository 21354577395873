<div class="d-flex flex-row-reverse ms-auto mb-2">
    <button class="btn btn-md btn-flat-primary me-1 btn-icon-start" (click)="exportCsv()"   translate>
      <em class="icon">save_alt</em> common-buttons.CSV-export
    </button>
    <button  class="btn btn-md me-1 btn-primary" (click)="createParty()"   translate>
      instructions.createParty
     </button>
    <button  class="btn btn-md me-1 btn-primary" (click)="ModifySSI()"   translate>
      instructions.modifySSI
     </button>
    <button class="btn btn-md me-2 btn-primary"  (click)="createSSI()"   translate>
      instructions.createSSI
    </button>

    <ng-content select="[searchTemplate]"></ng-content>
  </div>
<div>
    <ag-grid-angular #agGrid class="ag-theme-sg-bootstrap-condensed" [gridOptions]="gridOptions!" [rowGroupPanelShow]="rowGroupPanelShow"   [sideBar]="sideBar"
        [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [rowData]="instructions"
        (gridReady)="onGridReady($event)">
    </ag-grid-angular>
</div>
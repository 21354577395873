import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { PartyFormService } from '../services/party.form.service';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { SSIPartyModel } from '../model/ssiParty.model';
import { FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';

@Component({
  selector: 'app-ssi-instrument-party-form',
  templateUrl: './ssi-instrument-party-form.component.html',
  providers: [
    { provide: FormServiceInjectionToken, useExisting: PartyFormService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'instructions.creation' }
  ]
})
export class SSIInstrumentPartyFormComponent extends BaseFormComponent<SSIPartyModel, PartyFormService> {
  constructor(private readonly activeModal: NgbActiveModal, private readonly partyFormService: PartyFormService) {
    super(partyFormService, 'instructions.form');
  }

  public ClosedReason: DialogResult = 'closed';
  public CancelReason: DialogResult = 'cancel';
  public ConfirmReason: DialogResult = 'confirm';
  private readonly subscriptions: Subscription[] = [];

  confirmButton = 'modals.buttons.confirm';
  cancelButton = 'modals.buttons.cancel';

  public onClose(): void {
    this.activeModal.dismiss(this.ClosedReason);
    this.activeModal.close(this.ClosedReason);
  }

  public onCancel(): void {
    this.activeModal.close(this.CancelReason);
  }

  public onConfirm(): void {
    this.partyFormService.updateValueAndValidity();
    if (this.partyFormService.valid) {
      this.activeModal.close(this.ConfirmReason);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

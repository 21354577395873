import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { InstructionFormComponent } from '../instruction-form/instruction-form.component';
import { SSIInstrumentPartyFormComponent } from '../ssi-instrument-party-form/ssi-instrument-party-form.component';

@Injectable()
export class InstructionModalsService {
  constructor(private readonly modalService: NgbModal) {}

  public openCreateSSIModal(): NgbModalRef {
    const modal = this.modalService.open(InstructionFormComponent, { size: 'lg', scrollable: true });
    const dialog: InstructionFormComponent = modal.componentInstance;
    dialog.modify = false;
    dialog.dialogTitle = 'modals.titles.createInstruction';
    return modal;
  }

  public openModifySSIModal(): NgbModalRef {
    const modal = this.modalService.open(InstructionFormComponent, { size: 'lg', scrollable: true });
    const dialog: InstructionFormComponent = modal.componentInstance;
    dialog.modify = true;
    dialog.dialogTitle = 'modals.titles.modifyInstruction';
    return modal;
  }

  public openCreatePartyModal(): NgbModalRef {
    const modal = this.modalService.open(SSIInstrumentPartyFormComponent, { size: 'md', scrollable: true });
    return modal;
  }
}

import { ExtCashAccountLevelDto } from '@apis/backend/instruments';

export interface ExtCashAccountModel {
  idBdrParty?: string;
  currency?: string;
  correspondBankName?: string;
  correspondBankBIC?: string;
  intermediaryName?: string;
  intermediaryBIC?: string;
  account?: string;
  internalAccountName?: string;
  correspondBankAccount?: string;
  accountBIC?: string;
}

export const toExtCashAccountLevelDto = (extCash: ExtCashAccountModel): ExtCashAccountLevelDto => {
  return {
    idBdrParty: extCash.idBdrParty,
    currency: extCash.currency,
    correspondBankName: extCash.correspondBankName,
    correspondBankBIC: extCash.correspondBankBIC,
    intermediaryName: extCash.intermediaryName,
    intermediaryBIC: extCash.intermediaryBIC,
    account: extCash.account,
    internalAccountName: extCash.internalAccountName,
    correspondBankAccount: extCash.correspondBankAccount,
    accountBic: extCash.accountBIC
  };
};

export const toExtCashAccountModel = (extCash: any): ExtCashAccountModel => {
  return {
    idBdrParty: extCash.party.bdrId,
    currency: extCash.currency,
    correspondBankName: extCash.correspondBankName,
    correspondBankBIC: extCash.correspondBankBIC,
    intermediaryName: extCash.intermediaryName,
    intermediaryBIC: extCash.intermediaryBIC,
    account: extCash.account,
    internalAccountName: extCash.internalAccountName,
    correspondBankAccount: extCash.correspondBankAccount,
    accountBIC: extCash.accountBic
  };
};

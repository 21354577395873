import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastManagerService } from '@shared/modules/toasts/service/toastManager.service';
import { PaymentService } from '@shared/services/payment.service';
import { PaymentDetailFormService, PaymentModalsService } from '../../services';
import { routeToPayment } from '../../routes';
import { PIWIK_CONST } from 'src/app/core/analytics/piwik-tracking.constants';
import { Track } from '@sgwt/analytics-core';

@Component({
  selector: 'app-payment-save-cancel',
  templateUrl: './payment-save-cancel.component.html',
  providers: [PaymentModalsService]
})
export class PaymentSaveCancelComponent {
  constructor(
    private readonly paymentDetailFormService: PaymentDetailFormService,
    private readonly paymentService: PaymentService,
    private readonly modalsService: PaymentModalsService,
    private readonly toastService: ToastManagerService,
    private readonly router: Router
  ) {}

  onSaveClick() {
    this.paymentDetailFormService.updateValueAndValidity();
    const paymentStatus = this.paymentDetailFormService.value('paymentStatus');
    const paymentRef = this.paymentDetailFormService.value('paymentReference');
    const remarks = this.paymentDetailFormService.value('comments') || undefined;
    if (!paymentStatus) {
      this.toastService.toastWarning('toasts.instruments.invalid-form.title', 'toasts.instruments.invalid-form.message');
      return;
    }

    if (this.paymentDetailFormService.valid && paymentRef) {
      const modal = this.modalsService.openSaveModal();
      modal.result.then((res: DialogResult) => {
        if (res !== 'confirm') {
          return;
        }
        this.savePayment(paymentRef, paymentStatus, remarks);
      });
    }
  }

  onCancelClick() {
    if (this.paymentDetailFormService.dirty) {
      const modal = this.modalsService.openCancelModal();
      modal.result.then((res: DialogResult) => {
        if (res !== 'confirm') {
          return;
        }

        this.goBack();
      });
      return;
    }
    this.goBack();
  }

  savePayment(paymentRef: number, paymentStatus?: string | undefined, remarks?: string | undefined) {
    //piwik Event Tracking
    (window as any)._paq.push(['trackEvent', PIWIK_CONST.eventCategories.Payment, PIWIK_CONST.eventActions.updatePayment]);
    this.paymentService.updatePaymentStatus(paymentRef, paymentStatus, remarks).subscribe(response => {
      if (response) {
        this.goBack();
      }
    });
  }

  @Track([PIWIK_CONST.methods.trackEvent, PIWIK_CONST.eventCategories.Payment, PIWIK_CONST.eventActions.onBackClicked])
  private goBack() {
    const paymentRef = this.paymentDetailFormService.value('paymentReference');
    this.router.navigate(routeToPayment(paymentRef, 'consult'));
  }
}

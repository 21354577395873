import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { InstructionFormService } from '../services/instruction-form.service';

@Component({
  selector: 'app-instruction-form',
  templateUrl: './instruction-form.component.html'
})
export class InstructionFormComponent {
  public ClosedReason: DialogResult = 'closed';
  public CancelReason: DialogResult = 'cancel';
  public ConfirmReason: DialogResult = 'confirm';
  private readonly subscriptions: Subscription[] = [];

  displayFooter = false;
  confirmButton = 'modals.buttons.confirm';
  cancelButton = 'modals.buttons.cancel';

  @Input() modify = false;
  @Input() dialogTitle = 'modals.titles.default';

  constructor(private readonly activeModal: NgbActiveModal, private readonly instructionFormService: InstructionFormService) {}

  public onClose(): void {
    this.activeModal.dismiss(this.ClosedReason);
    this.activeModal.close(this.ClosedReason);
  }

  public onCancel(): void {
    this.activeModal.close(this.CancelReason);
  }

  public onConfirm(): void {
    this.instructionFormService.updateValueAndValidity();
    if (this.instructionFormService.valid) {
      this.activeModal.close(this.ConfirmReason);
    }
  }

  public displayConfirm(): void {
    this.displayFooter = true;
  }

  ngOnDestroy(): void {
    this.instructionFormService.clearInitialValue();
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}

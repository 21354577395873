<div class="modal-header">
    <h3 class="modal-title">{{ dialogTitle | translate }}</h3>
    <button type="button" class="close icon p-1" (click)="onClose()">close</button>
</div>
<div class="modal-body">
<app-ecash-account (displayConfirm)="displayConfirm()" [modify]="modify"></app-ecash-account>
</div>
<div class="modal-footer" *ngIf="modify && displayFooter">
    <button type="button" (click)="onCancel()" (keyup.enter)="onCancel()" class="btn btn-lg btn-link">{{ cancelButton |
        translate }}</button>
    <button ngbAutofocus type="button" (click)="onConfirm()" (keyup.enter)="onConfirm()" class="btn btn-lg btn-primary"
        translate>{{ confirmButton | translate }}</button>
</div>
<div class="modal-footer" *ngIf="!modify">
    <button type="button" (click)="onCancel()" (keyup.enter)="onCancel()" class="btn btn-lg btn-link">{{ cancelButton |
        translate }}</button>
    <button ngbAutofocus type="button" (click)="onConfirm()" (keyup.enter)="onConfirm()" class="btn btn-lg btn-primary"
        translate>{{ confirmButton | translate }}</button>
</div>

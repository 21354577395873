import { Component } from '@angular/core';
import { FormServiceInjectionToken, LabelRootTranslateInjectionToken } from '@shared/modules/forms/tokens';
import { BaseFormComponent } from '@shared/components/base/base-form-component';
import { ActivatedRoute, Router } from '@angular/router';
import { routeToValuation } from '@valuation/routes';
import { combineLatest, Subject } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { Forms } from '@shared/utils';
import { ValuationDetailFormService } from '@valuation/services';
import { ValuationDetailModel } from '@valuation/models';
import { VALUATION_CONSTANTS } from '@valuation/configs/valuation-constants';
import { EventDetailModel } from '@events/models';
import { PermissionService } from '@shared/services/permission.service';
import { LocationService } from '@shared/services/location.service';
import { PIWIK_CONST } from 'src/app/core/analytics/piwik-tracking.constants';
import { Track } from '@sgwt/analytics-core';

@Component({
  selector: 'app-valuation-detail',
  templateUrl: './valuation-detail.component.html',
  styleUrls: ['./valuation-detail.component.scss'],
  providers: [
    { provide: FormServiceInjectionToken, useExisting: ValuationDetailFormService },
    { provide: LabelRootTranslateInjectionToken, useValue: 'valuation.form' }
  ]
})
export class ValuationDetailComponent extends BaseFormComponent<ValuationDetailModel, ValuationDetailFormService> {
  public shutdown$ = new Subject<void>();
  public currentValuationStatus: string | null | undefined = '';
  public allValuationStatus: string[] | undefined;
  public valuationId: number | undefined | null;
  public mismatchColor = 'red !important';
  public dcfColorMismatched: string | null = '';
  public denominationValueMismatched: string | null = '';
  public denominationCcyMismatched: string | null = '';
  public redmTypeMismatched: string | null = '';
  public redmRateMismatched: string | null = '';
  public startDateValueMismatched: string | null = '';
  public endDateValueMismatched: string | null = '';
  public amountPerDenominationValueMismatched: string | null = '';
  public canModifyValuation = false;

  constructor(
    private readonly valuationDetailFormService: ValuationDetailFormService,
    private readonly permissionService: PermissionService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly locationService: LocationService
  ) {
    super(valuationDetailFormService, 'valuation.detail');
  }
  ngOnInit(): void {
    this.permissionService.canEditValuation$.pipe(takeUntil(this.shutdown$)).subscribe({
      next: (canModifyValuation: boolean) => {
        this.canModifyValuation = canModifyValuation;
      }
    });

    combineLatest([this.activatedRoute.data.pipe(startWith(...[])), this.activatedRoute.params.pipe(startWith(...[]))])
      .pipe(takeUntil(this.shutdown$))
      .subscribe(([routeData, routeParams]) => {
        const data = routeData['valuation'] as [ValuationDetailModel | null, EventDetailModel | null];
        const eventDetail = data.length > 0 ? data[1] : null;
        const valuationData = data.length > 0 ? data[0] : (data as ValuationDetailModel);
        const pageType = Forms.isFormMode(routeParams.pageType) ? routeParams.pageType : 'consult';
        this.valuationDetailFormService.setFormMode(pageType);
        if (pageType === 'consult') {
          this.valuationDetailFormService.setInitialValue(valuationData);
        }
        this.allValuationStatus = VALUATION_CONSTANTS.valuation.valuationStatusDropDown;
        this.valuationDetailFormService.valuationLockFields();
        this.currentValuationStatus = this.valuationDetailFormService.value('valuationStatus');
        if (valuationData && eventDetail && this.currentValuationStatus === 'NOT RECONCILED') {
          if (valuationData.eventType === 'INTR') {
            this.highlightMismatchedDataForINTR(valuationData, eventDetail);
          }
          if (valuationData.eventType === 'MCAL' || valuationData.eventType === 'REDM') {
            this.highlightMismatchedDataForREDMAndMCAL(valuationData, eventDetail);
          }
        }
        this.valuationId = this.valuationDetailFormService.value('valuationRef');
        if (this.currentValuationStatus && VALUATION_CONSTANTS.valuation.valuationStatus.includes(this.currentValuationStatus)) {
          this.valuationDetailFormService.valuationStatusLockFields();
        } else {
          this.valuationDetailFormService.valuationStatusUnLockFields();
        }
      });
  }

  public get isEditMode(): boolean {
    return this.valuationDetailFormService.formMode === 'edit';
  }

  public get displayRedemptionField(): boolean {
    const eventType = this.formService.rawValue('eventType');
    return eventType ? !VALUATION_CONSTANTS.eventTypes.displayRedemptionFieldsInTypes.includes(eventType) : true;
  }

  public get displayInterestField(): boolean {
    const eventType = this.formService.rawValue('eventType');
    return eventType !== VALUATION_CONSTANTS.eventTypes.INTR;
  }

  public get isPredPcalEvent(): boolean {
    const eventType = this.formService.rawValue('eventType');
    return eventType ? !VALUATION_CONSTANTS.eventTypes.displayPredPcalFieldsInTypes.includes(eventType) : true;
  }

  public get isStatusAwaitNewValuation() {
    const valuationStatus = this.formService.rawValue('valuationStatus');
    return valuationStatus ? VALUATION_CONSTANTS.valuationStatusSearchCondtion.includes(valuationStatus) : false;
  }
  @Track([PIWIK_CONST.methods.trackEvent, PIWIK_CONST.eventCategories.Valuation, PIWIK_CONST.eventActions.onEditClicked])
  onEditClick() {
    this.router.navigate(routeToValuation(this.valuationId, 'edit'));
  }
  @Track([PIWIK_CONST.methods.trackEvent, PIWIK_CONST.eventCategories.Valuation, PIWIK_CONST.eventActions.onBackClicked])
  onBackClick() {
    if (this.locationService.navgationHistories.length >= 1) {
      this.router.navigateByUrl(this.locationService.getBackUrl());
      return;
    }
    this.router.navigate(routeToValuation());
  }

  private highlightMismatchedDataForREDMAndMCAL(valuation: ValuationDetailModel, event: EventDetailModel) {
    if (event.redemptionType?.valueDescription !== valuation.redemptionType?.valueDescription) {
      this.redmTypeMismatched = this.mismatchColor;
    }
    if (event.tradingMethodIdentifier === 'UNIT') {
      if (Number(event.eventRedemptionUnit?.replace(/,/gi, '')) !== Number(valuation.redemptionRate?.replace(/,/gi, ''))) {
        this.redmRateMismatched = this.mismatchColor;
      }
    } else {
      if (Number(event.eventRedemptionPrice?.replace(/,/gi, '')) !== Number(valuation.redemptionRate?.replace(/,/gi, ''))) {
        this.redmRateMismatched = this.mismatchColor;
      }
    }
    if (event.denominationAsString !== valuation.denomination) {
      this.denominationValueMismatched = this.mismatchColor;
    }
    if (event.paymentCurrency !== valuation.paymentCcy) {
      this.denominationCcyMismatched = this.mismatchColor;
    }
  }

  private highlightMismatchedDataForINTR(valuation: ValuationDetailModel, event: EventDetailModel) {
    const compatibleDayCountFraction = ['30A/360', '30E/360'];
    if (event.dayCountFraction && valuation.dayCountFraction && !(compatibleDayCountFraction.includes(event.dayCountFraction) && compatibleDayCountFraction.includes(valuation.dayCountFraction))) {
      if (event.dayCountFraction !== valuation.dayCountFraction) {
        this.dcfColorMismatched = this.mismatchColor;
      }
    }

    if (event.denominationAsString !== valuation.denomination) {
      this.denominationValueMismatched = this.mismatchColor;
    }

    if (event.beginDate !== valuation.startDate) {
      this.startDateValueMismatched = this.mismatchColor;
    }

    if (event.endDate !== valuation.endDate) {
      this.endDateValueMismatched = this.mismatchColor;
    }

    if (event.amountPerDenomination !== valuation.amountPerDenomination) {
      this.amountPerDenominationValueMismatched = this.mismatchColor;
    }

    if (event.paymentCurrency !== valuation.paymentCcy) {
      this.denominationCcyMismatched = this.mismatchColor;
    }
  }
}

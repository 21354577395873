/**
 * Instrument API
 * Manage Instrument data
 *
 * The version of the OpenAPI document: 1.1.59
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { GlobalNoteRegenerationRequest } from '../model/models';
import { GnReceptionDataDto } from '../model/models';
import { IdDto } from '../model/models';
import { InstrumentCreateDto } from '../model/models';
import { InstrumentCriteriaSearchDto } from '../model/models';
import { InstrumentDto } from '../model/models';
import { InstrumentEventMaturityDateDto } from '../model/models';
import { InstrumentIdTrancheNumberDto } from '../model/models';
import { InstrumentTrancheCreateDto } from '../model/models';
import { InstrumentTrancheUpdateDto } from '../model/models';
import { InstrumentUpdateDto } from '../model/models';
import { MatrixInstrumentRoleDto } from '../model/models';
import { MatrixInstrumentRoleRequestDto } from '../model/models';
import { MatrixInstrumentTypeIcsdRequestDto } from '../model/models';
import { PagingRequestDtoWithCriteriaDto } from '../model/models';
import { PagingResultDtoInstrumentDataFetchDto } from '../model/models';
import { Problem } from '../model/models';
import { ResultInstrumentDto } from '../model/models';
import { ResultMatrixInstrumentTypeIcsdResponseDto } from '../model/models';
import { SettlementDto } from '../model/models';
import { SettlementIdsDto } from '../model/models';
import { SettlementPerformDto } from '../model/models';
import { SwiftStoreRequestsDto } from '../model/models';
import { SwiftStoreResponseDto } from '../model/models';
import { SwiftStoreResponsesDto } from '../model/models';
import { UpdateSettlementStatusRequestDto } from '../model/models';
import { UpdateStatusRequestDto } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class InstrumentResourceService {

    protected basePath = 'https://issuer-ipa-develop.dev.socgen';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * validation of confirmations
     * validation of confirmations  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.instrument.confirmation.perform; Authorities: SGM_ISS_PAY_api.issuer-ipa.instrument.confirmation.perform
     * @param zipFile ZIP file to be perform confirmation
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public confirmationsByZip(zipFile: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any>;
    public confirmationsByZip(zipFile: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpResponse<any>>;
    public confirmationsByZip(zipFile: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpEvent<any>>;
    public confirmationsByZip(zipFile: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any> {
        if (zipFile === null || zipFile === undefined) {
            throw new Error('Required parameter zipFile was null or undefined when calling confirmationsByZip.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: this.encoder});
        }

        if (zipFile !== undefined) {
            formParams = formParams.append('zipFile', <any>zipFile) as any || formParams;
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/instruments/_confirmations-by-zip`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createInstrument
     * Create a new instrument  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.instrument.create; Authorities: SGM_ISS_PAY_api.issuer-ipa.instrument.create
     * @param instrumentCreateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createInstrument(instrumentCreateDto: InstrumentCreateDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<IdDto>;
    public createInstrument(instrumentCreateDto: InstrumentCreateDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpResponse<IdDto>>;
    public createInstrument(instrumentCreateDto: InstrumentCreateDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpEvent<IdDto>>;
    public createInstrument(instrumentCreateDto: InstrumentCreateDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any> {
        if (instrumentCreateDto === null || instrumentCreateDto === undefined) {
            throw new Error('Required parameter instrumentCreateDto was null or undefined when calling createInstrument.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<IdDto>(`${this.configuration.basePath}/api/v1/instruments`,
            instrumentCreateDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createOrUpdateGlobalNote
     * Create or Update a new Global note  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.globalnote.update; Authorities: SGM_ISS_PAY_api.issuer-ipa.globalnote.update
     * @param gnReceptionDataDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createOrUpdateGlobalNote(gnReceptionDataDto: GnReceptionDataDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any>;
    public createOrUpdateGlobalNote(gnReceptionDataDto: GnReceptionDataDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpResponse<any>>;
    public createOrUpdateGlobalNote(gnReceptionDataDto: GnReceptionDataDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpEvent<any>>;
    public createOrUpdateGlobalNote(gnReceptionDataDto: GnReceptionDataDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any> {
        if (gnReceptionDataDto === null || gnReceptionDataDto === undefined) {
            throw new Error('Required parameter gnReceptionDataDto was null or undefined when calling createOrUpdateGlobalNote.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/instruments/_createOrUpdateGlobalNote`,
            gnReceptionDataDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * createTranche
     * Create a new tranche  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.instrument.create; Authorities: SGM_ISS_PAY_api.issuer-ipa.instrument.create
     * @param instrumentId 
     * @param instrumentTrancheCreateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createTranche(instrumentId: number, instrumentTrancheCreateDto: InstrumentTrancheCreateDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<InstrumentIdTrancheNumberDto>;
    public createTranche(instrumentId: number, instrumentTrancheCreateDto: InstrumentTrancheCreateDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpResponse<InstrumentIdTrancheNumberDto>>;
    public createTranche(instrumentId: number, instrumentTrancheCreateDto: InstrumentTrancheCreateDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpEvent<InstrumentIdTrancheNumberDto>>;
    public createTranche(instrumentId: number, instrumentTrancheCreateDto: InstrumentTrancheCreateDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any> {
        if (instrumentId === null || instrumentId === undefined) {
            throw new Error('Required parameter instrumentId was null or undefined when calling createTranche.');
        }
        if (instrumentTrancheCreateDto === null || instrumentTrancheCreateDto === undefined) {
            throw new Error('Required parameter instrumentTrancheCreateDto was null or undefined when calling createTranche.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<InstrumentIdTrancheNumberDto>(`${this.configuration.basePath}/api/v1/instruments/${encodeURIComponent(String(instrumentId))}/tranches`,
            instrumentTrancheCreateDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * exportInstrumentsData
     * Export light instruments data by search criteria  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.instrument.multiple.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.instrument.multiple.read
     * @param instrumentCriteriaSearchDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportInstrumentsData(instrumentCriteriaSearchDto: InstrumentCriteriaSearchDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/problem+json'}): Observable<Blob>;
    public exportInstrumentsData(instrumentCriteriaSearchDto: InstrumentCriteriaSearchDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/problem+json'}): Observable<HttpResponse<Blob>>;
    public exportInstrumentsData(instrumentCriteriaSearchDto: InstrumentCriteriaSearchDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/problem+json'}): Observable<HttpEvent<Blob>>;
    public exportInstrumentsData(instrumentCriteriaSearchDto: InstrumentCriteriaSearchDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/octet-stream' | 'application/problem+json'}): Observable<any> {
        if (instrumentCriteriaSearchDto === null || instrumentCriteriaSearchDto === undefined) {
            throw new Error('Required parameter instrumentCriteriaSearchDto was null or undefined when calling exportInstrumentsData.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/octet-stream',
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.configuration.basePath}/api/v1/instruments/_export`,
            instrumentCriteriaSearchDto,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * _generateAndSendGlobalNote
     * Create or Update a new Global note  **Authorization policy:**  Scopes: api.issuer-ipa.v1
     * @param globalNoteRegenerationRequest 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateAndSendGlobalNote(globalNoteRegenerationRequest: GlobalNoteRegenerationRequest, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any>;
    public generateAndSendGlobalNote(globalNoteRegenerationRequest: GlobalNoteRegenerationRequest, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpResponse<any>>;
    public generateAndSendGlobalNote(globalNoteRegenerationRequest: GlobalNoteRegenerationRequest, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpEvent<any>>;
    public generateAndSendGlobalNote(globalNoteRegenerationRequest: GlobalNoteRegenerationRequest, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any> {
        if (globalNoteRegenerationRequest === null || globalNoteRegenerationRequest === undefined) {
            throw new Error('Required parameter globalNoteRegenerationRequest was null or undefined when calling generateAndSendGlobalNote.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/instruments/_generateAndSendGlobalNote`,
            globalNoteRegenerationRequest,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generate daily MT535
     * Generate all MT535  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.instrument.multiple.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.instrument.multiple.read
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateMT535(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any>;
    public generateMT535(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpResponse<any>>;
    public generateMT535(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpEvent<any>>;
    public generateMT535(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/swifts/_generateDailyMT535`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generate daily MT535 for a given value date
     * Generate all MT535 for a given value date  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.instrument.multiple.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.instrument.multiple.read
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateMT535ForValueDate(body: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any>;
    public generateMT535ForValueDate(body: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpResponse<any>>;
    public generateMT535ForValueDate(body: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpEvent<any>>;
    public generateMT535ForValueDate(body: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling generateMT535ForValueDate.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/swifts/_generateDailyMT535ForValueDate`,
            body,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generate daily MT536
     * Generate all MT536  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.instrument.multiple.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.instrument.multiple.read
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateMT536(body: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any>;
    public generateMT536(body: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpResponse<any>>;
    public generateMT536(body: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpEvent<any>>;
    public generateMT536(body: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling generateMT536.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/swifts/_generateDailyMT536`,
            body,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllIndicatorsByThemeAndCategory
     * Get global notes by theme and category.  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.globalnote.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.globalnote.read
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllIndicatorsByThemeAndCategory(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any>;
    public getAllIndicatorsByThemeAndCategory(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<any>>;
    public getAllIndicatorsByThemeAndCategory(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<any>>;
    public getAllIndicatorsByThemeAndCategory(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/api/v1/instruments/getAllIndicators`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getAllSettlementsByIdSettlements
     * Get multiple settlements by tranche number and instrument Id if it is filled  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.settlement.multiple.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.settlement.multiple.read
     * @param instrumentId instrumentId)
     * @param trancheNumber trancheNumber)
     * @param idSettlement 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllSettlementsByIdSettlements(instrumentId: number, trancheNumber: number, idSettlement?: Array<number>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<SettlementDto>;
    public getAllSettlementsByIdSettlements(instrumentId: number, trancheNumber: number, idSettlement?: Array<number>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<SettlementDto>>;
    public getAllSettlementsByIdSettlements(instrumentId: number, trancheNumber: number, idSettlement?: Array<number>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<SettlementDto>>;
    public getAllSettlementsByIdSettlements(instrumentId: number, trancheNumber: number, idSettlement?: Array<number>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (instrumentId === null || instrumentId === undefined) {
            throw new Error('Required parameter instrumentId was null or undefined when calling getAllSettlementsByIdSettlements.');
        }
        if (trancheNumber === null || trancheNumber === undefined) {
            throw new Error('Required parameter trancheNumber was null or undefined when calling getAllSettlementsByIdSettlements.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (idSettlement) {
            idSettlement.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'idSettlement');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<SettlementDto>(`${this.configuration.basePath}/api/v1/instruments/${encodeURIComponent(String(instrumentId))}/tranches/${encodeURIComponent(String(trancheNumber))}/settlements`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getGlobalNoteByInstrumentId
     * Get global note by instrument id.  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.globalnote.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.globalnote.read
     * @param instrumentId Id of instrument
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGlobalNoteByInstrumentId(instrumentId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any>;
    public getGlobalNoteByInstrumentId(instrumentId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<any>>;
    public getGlobalNoteByInstrumentId(instrumentId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<any>>;
    public getGlobalNoteByInstrumentId(instrumentId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (instrumentId === null || instrumentId === undefined) {
            throw new Error('Required parameter instrumentId was null or undefined when calling getGlobalNoteByInstrumentId.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<any>(`${this.configuration.basePath}/api/v1/instruments/${encodeURIComponent(String(instrumentId))}/_global-note`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getInstrumentById
     * Get instrument by id  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.instrument.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.instrument.read
     * @param id Id of instrument
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInstrumentById(id: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<InstrumentDto>;
    public getInstrumentById(id: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<InstrumentDto>>;
    public getInstrumentById(id: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<InstrumentDto>>;
    public getInstrumentById(id: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getInstrumentById.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<InstrumentDto>(`${this.configuration.basePath}/api/v1/instruments/${encodeURIComponent(String(id))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getInstrumentByIsin
     * Get instrument id by isin  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.instrument.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.instrument.read
     * @param isin isin of instrument tranche
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInstrumentBySingleIsin(isin: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<InstrumentDto>;
    public getInstrumentBySingleIsin(isin: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<InstrumentDto>>;
    public getInstrumentBySingleIsin(isin: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<InstrumentDto>>;
    public getInstrumentBySingleIsin(isin: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (isin === null || isin === undefined) {
            throw new Error('Required parameter isin was null or undefined when calling getInstrumentBySingleIsin.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<InstrumentDto>(`${this.configuration.basePath}/api/v1/instruments/do-search-by-isin/${encodeURIComponent(String(isin))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getMatrixInstrumentRole
     * Get a Role data for instrument  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.instrument.matrix.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.instrument.matrix.read
     * @param matrixInstrumentRoleRequestDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMatrixInstrumentRole(matrixInstrumentRoleRequestDto: MatrixInstrumentRoleRequestDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<MatrixInstrumentRoleDto>;
    public getMatrixInstrumentRole(matrixInstrumentRoleRequestDto: MatrixInstrumentRoleRequestDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpResponse<MatrixInstrumentRoleDto>>;
    public getMatrixInstrumentRole(matrixInstrumentRoleRequestDto: MatrixInstrumentRoleRequestDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpEvent<MatrixInstrumentRoleDto>>;
    public getMatrixInstrumentRole(matrixInstrumentRoleRequestDto: MatrixInstrumentRoleRequestDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any> {
        if (matrixInstrumentRoleRequestDto === null || matrixInstrumentRoleRequestDto === undefined) {
            throw new Error('Required parameter matrixInstrumentRoleRequestDto was null or undefined when calling getMatrixInstrumentRole.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<MatrixInstrumentRoleDto>(`${this.configuration.basePath}/api/v1/instruments/_getMatrixInstrumentRole`,
            matrixInstrumentRoleRequestDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getMatrixInstrumentIcsd
     * Get a ICSD for instrument  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.instrument.matrix.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.instrument.matrix.read
     * @param matrixInstrumentTypeIcsdRequestDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMatrixInstrumentTypeIcsd(matrixInstrumentTypeIcsdRequestDto: MatrixInstrumentTypeIcsdRequestDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<ResultMatrixInstrumentTypeIcsdResponseDto>;
    public getMatrixInstrumentTypeIcsd(matrixInstrumentTypeIcsdRequestDto: MatrixInstrumentTypeIcsdRequestDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpResponse<ResultMatrixInstrumentTypeIcsdResponseDto>>;
    public getMatrixInstrumentTypeIcsd(matrixInstrumentTypeIcsdRequestDto: MatrixInstrumentTypeIcsdRequestDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpEvent<ResultMatrixInstrumentTypeIcsdResponseDto>>;
    public getMatrixInstrumentTypeIcsd(matrixInstrumentTypeIcsdRequestDto: MatrixInstrumentTypeIcsdRequestDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any> {
        if (matrixInstrumentTypeIcsdRequestDto === null || matrixInstrumentTypeIcsdRequestDto === undefined) {
            throw new Error('Required parameter matrixInstrumentTypeIcsdRequestDto was null or undefined when calling getMatrixInstrumentTypeIcsd.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ResultMatrixInstrumentTypeIcsdResponseDto>(`${this.configuration.basePath}/api/v1/instruments/_getMatrixInstrumentIcsd`,
            matrixInstrumentTypeIcsdRequestDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getMultipleInstruments
     * Get multiple instruments by ids if is filled or most recent instruments (within the limit of 100 instruments).  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.instrument.multiple.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.instrument.multiple.read
     * @param id Ids of instruments split by ,
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMultipleInstruments(id?: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<ResultInstrumentDto>;
    public getMultipleInstruments(id?: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<ResultInstrumentDto>>;
    public getMultipleInstruments(id?: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<ResultInstrumentDto>>;
    public getMultipleInstruments(id?: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResultInstrumentDto>(`${this.configuration.basePath}/api/v1/instruments`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getMultipleInstrumentsByIsins
     * Get multiple instruments id by isins  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.instrument.multiple.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.instrument.multiple.read
     * @param isins 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMultipleInstrumentsByIsins(isins: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<ResultInstrumentDto>;
    public getMultipleInstrumentsByIsins(isins: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<ResultInstrumentDto>>;
    public getMultipleInstrumentsByIsins(isins: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<ResultInstrumentDto>>;
    public getMultipleInstrumentsByIsins(isins: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (isins === null || isins === undefined) {
            throw new Error('Required parameter isins was null or undefined when calling getMultipleInstrumentsByIsins.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (isins) {
            isins.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'isins');
            })
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<ResultInstrumentDto>(`${this.configuration.basePath}/api/v1/instruments/do-search-by-isin`,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * getSettlementById
     * Get settlement by id  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.settlement.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.settlement.read
     * @param instrumentId 
     * @param trancheNumber 
     * @param idSettlement Id of settlement
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getSettlementByInstrumentIdTrancheNumberAndIdSettlement(instrumentId: number, trancheNumber: number, idSettlement: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<SettlementDto>;
    public getSettlementByInstrumentIdTrancheNumberAndIdSettlement(instrumentId: number, trancheNumber: number, idSettlement: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<SettlementDto>>;
    public getSettlementByInstrumentIdTrancheNumberAndIdSettlement(instrumentId: number, trancheNumber: number, idSettlement: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<SettlementDto>>;
    public getSettlementByInstrumentIdTrancheNumberAndIdSettlement(instrumentId: number, trancheNumber: number, idSettlement: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (instrumentId === null || instrumentId === undefined) {
            throw new Error('Required parameter instrumentId was null or undefined when calling getSettlementByInstrumentIdTrancheNumberAndIdSettlement.');
        }
        if (trancheNumber === null || trancheNumber === undefined) {
            throw new Error('Required parameter trancheNumber was null or undefined when calling getSettlementByInstrumentIdTrancheNumberAndIdSettlement.');
        }
        if (idSettlement === null || idSettlement === undefined) {
            throw new Error('Required parameter idSettlement was null or undefined when calling getSettlementByInstrumentIdTrancheNumberAndIdSettlement.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<SettlementDto>(`${this.configuration.basePath}/api/v1/instruments/${encodeURIComponent(String(instrumentId))}/tranches/${encodeURIComponent(String(trancheNumber))}/settlements/${encodeURIComponent(String(idSettlement))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * performSettlements
     * Perform an action on settlements  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.settlement.perform; Authorities: SGM_ISS_PAY_api.issuer-ipa.settlement.perform
     * @param instrumentId 
     * @param trancheNumber 
     * @param settlementPerformDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public performSettlements(instrumentId: number, trancheNumber: number, settlementPerformDto: Array<SettlementPerformDto>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<SettlementIdsDto>;
    public performSettlements(instrumentId: number, trancheNumber: number, settlementPerformDto: Array<SettlementPerformDto>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<SettlementIdsDto>>;
    public performSettlements(instrumentId: number, trancheNumber: number, settlementPerformDto: Array<SettlementPerformDto>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<SettlementIdsDto>>;
    public performSettlements(instrumentId: number, trancheNumber: number, settlementPerformDto: Array<SettlementPerformDto>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (instrumentId === null || instrumentId === undefined) {
            throw new Error('Required parameter instrumentId was null or undefined when calling performSettlements.');
        }
        if (trancheNumber === null || trancheNumber === undefined) {
            throw new Error('Required parameter trancheNumber was null or undefined when calling performSettlements.');
        }
        if (settlementPerformDto === null || settlementPerformDto === undefined) {
            throw new Error('Required parameter settlementPerformDto was null or undefined when calling performSettlements.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.patch<SettlementIdsDto>(`${this.configuration.basePath}/api/v1/instruments/${encodeURIComponent(String(instrumentId))}/tranches/${encodeURIComponent(String(trancheNumber))}/settlements`,
            settlementPerformDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Process all MT536 received swifts
     * Process all MT536 received swifts  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.instrument.multiple.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.instrument.multiple.read
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public processMT536integration(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any>;
    public processMT536integration(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpResponse<any>>;
    public processMT536integration(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpEvent<any>>;
    public processMT536integration(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/swifts/_processAllMT536`,
            null,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Process the content of a received swift message
     * Gets the content of a received swift message  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.instrument.multiple.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.instrument.multiple.read
     * @param receivedSwiftId Get the Received Swift Message mathcing an id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public processSwiftReceivedById(receivedSwiftId: number, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<SwiftStoreResponseDto>;
    public processSwiftReceivedById(receivedSwiftId: number, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<SwiftStoreResponseDto>>;
    public processSwiftReceivedById(receivedSwiftId: number, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<SwiftStoreResponseDto>>;
    public processSwiftReceivedById(receivedSwiftId: number, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (receivedSwiftId === null || receivedSwiftId === undefined) {
            throw new Error('Required parameter receivedSwiftId was null or undefined when calling processSwiftReceivedById.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (receivedSwiftId !== undefined && receivedSwiftId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>receivedSwiftId, 'receivedSwiftId');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SwiftStoreResponseDto>(`${this.configuration.basePath}/api/v1/swifts/_processMT536BySwiftReceivedId`,
            null,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchInstrumentsPaged
     * All instruments are returned paginated  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.instrument.multiple.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.instrument.multiple.read
     * @param pagingRequestDtoWithCriteriaDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchInstrumentsPaged(pagingRequestDtoWithCriteriaDto: PagingRequestDtoWithCriteriaDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json' }): Observable<PagingResultDtoInstrumentDataFetchDto>;
    public searchInstrumentsPaged(pagingRequestDtoWithCriteriaDto: PagingRequestDtoWithCriteriaDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json' }): Observable<HttpResponse<PagingResultDtoInstrumentDataFetchDto>>;
    public searchInstrumentsPaged(pagingRequestDtoWithCriteriaDto: PagingRequestDtoWithCriteriaDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json' }): Observable<HttpEvent<PagingResultDtoInstrumentDataFetchDto>>;
    public searchInstrumentsPaged(pagingRequestDtoWithCriteriaDto: PagingRequestDtoWithCriteriaDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json' }): Observable<any> {
        if (pagingRequestDtoWithCriteriaDto === null || pagingRequestDtoWithCriteriaDto === undefined) {
            throw new Error('Required parameter pagingRequestDtoWithCriteriaDto was null or undefined when calling searchInstrumentsPaged.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json',
                'application/json',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<PagingResultDtoInstrumentDataFetchDto>(`${this.configuration.basePath}/api/v1/instruments/_search`,
            pagingRequestDtoWithCriteriaDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * searchMultipleInstrumentsByIsins
     * Get multiple instruments id by isins  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.instrument.multiple.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.instrument.multiple.read
     * @param requestBody 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchMultipleInstrumentsByIsins(requestBody: Array<string>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<ResultInstrumentDto>;
    public searchMultipleInstrumentsByIsins(requestBody: Array<string>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpResponse<ResultInstrumentDto>>;
    public searchMultipleInstrumentsByIsins(requestBody: Array<string>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<HttpEvent<ResultInstrumentDto>>;
    public searchMultipleInstrumentsByIsins(requestBody: Array<string>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json'}): Observable<any> {
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling searchMultipleInstrumentsByIsins.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json',
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<ResultInstrumentDto>(`${this.configuration.basePath}/api/v1/instruments/search-by-multiple-isins`,
            requestBody,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * store swift message
     * Store swift message in database  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.instrument.multiple.read; Authorities: SGM_ISS_PAY_api.issuer-ipa.instrument.multiple.read
     * @param swiftStoreRequestsDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public storeSwiftMessagesInDatabase(swiftStoreRequestsDto: SwiftStoreRequestsDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json' }): Observable<SwiftStoreResponsesDto>;
    public storeSwiftMessagesInDatabase(swiftStoreRequestsDto: SwiftStoreRequestsDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json' }): Observable<HttpResponse<SwiftStoreResponsesDto>>;
    public storeSwiftMessagesInDatabase(swiftStoreRequestsDto: SwiftStoreRequestsDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json' }): Observable<HttpEvent<SwiftStoreResponsesDto>>;
    public storeSwiftMessagesInDatabase(swiftStoreRequestsDto: SwiftStoreRequestsDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json' | 'application/json' }): Observable<any> {
        if (swiftStoreRequestsDto === null || swiftStoreRequestsDto === undefined) {
            throw new Error('Required parameter swiftStoreRequestsDto was null or undefined when calling storeSwiftMessagesInDatabase.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json',
                'application/json',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<SwiftStoreResponsesDto>(`${this.configuration.basePath}/api/v1/swifts`,
            swiftStoreRequestsDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateInstrument
     * Update an instrument  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.instrument.update; Authorities: SGM_ISS_PAY_api.issuer-ipa.instrument.update
     * @param instrumentUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateInstrument(instrumentUpdateDto: InstrumentUpdateDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any>;
    public updateInstrument(instrumentUpdateDto: InstrumentUpdateDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpResponse<any>>;
    public updateInstrument(instrumentUpdateDto: InstrumentUpdateDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpEvent<any>>;
    public updateInstrument(instrumentUpdateDto: InstrumentUpdateDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any> {
        if (instrumentUpdateDto === null || instrumentUpdateDto === undefined) {
            throw new Error('Required parameter instrumentUpdateDto was null or undefined when calling updateInstrument.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/v1/instruments`,
            instrumentUpdateDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateInstrumentMaturityDate
     * Update maturity date for an instrument  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.instrument.eventbatch.update; Authorities: SGM_ISS_PAY_api.issuer-ipa.instrument.eventbatch.update
     * @param instrumentEventMaturityDateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateInstrumentMaturityDate(instrumentEventMaturityDateDto: Array<InstrumentEventMaturityDateDto>, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any>;
    public updateInstrumentMaturityDate(instrumentEventMaturityDateDto: Array<InstrumentEventMaturityDateDto>, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpResponse<any>>;
    public updateInstrumentMaturityDate(instrumentEventMaturityDateDto: Array<InstrumentEventMaturityDateDto>, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<HttpEvent<any>>;
    public updateInstrumentMaturityDate(instrumentEventMaturityDateDto: Array<InstrumentEventMaturityDateDto>, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'application/problem+json'}): Observable<any> {
        if (instrumentEventMaturityDateDto === null || instrumentEventMaturityDateDto === undefined) {
            throw new Error('Required parameter instrumentEventMaturityDateDto was null or undefined when calling updateInstrumentMaturityDate.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json',
                'text/plain',
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/api/v1/instruments/updateInstrumentMaturityDate`,
            instrumentEventMaturityDateDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateSettlementStatus
     * Update Status for Settlement  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.settlement.update.status; Authorities: SGM_ISS_PAY_api.issuer-ipa.settlement.update.status
     * @param updateStatusSettlementActionCodeEnum 
     * @param updateSettlementStatusRequestDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateSettlementStatus(updateStatusSettlementActionCodeEnum: 'DELIVERY_AND_SECURITIES_CREATION_AND_PAYMENT_TO_BE_VALIDATED' | 'CANCEL' | 'REJECT' | 'VALIDATE' | 'DELIVERY_TO_BE_VALIDATED' | 'SECURITIES_CREATION_TO_BE_VALIDATED' | 'PAYMENT_TO_BE_VALIDATED', updateSettlementStatusRequestDto: UpdateSettlementStatusRequestDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any>;
    public updateSettlementStatus(updateStatusSettlementActionCodeEnum: 'DELIVERY_AND_SECURITIES_CREATION_AND_PAYMENT_TO_BE_VALIDATED' | 'CANCEL' | 'REJECT' | 'VALIDATE' | 'DELIVERY_TO_BE_VALIDATED' | 'SECURITIES_CREATION_TO_BE_VALIDATED' | 'PAYMENT_TO_BE_VALIDATED', updateSettlementStatusRequestDto: UpdateSettlementStatusRequestDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpResponse<any>>;
    public updateSettlementStatus(updateStatusSettlementActionCodeEnum: 'DELIVERY_AND_SECURITIES_CREATION_AND_PAYMENT_TO_BE_VALIDATED' | 'CANCEL' | 'REJECT' | 'VALIDATE' | 'DELIVERY_TO_BE_VALIDATED' | 'SECURITIES_CREATION_TO_BE_VALIDATED' | 'PAYMENT_TO_BE_VALIDATED', updateSettlementStatusRequestDto: UpdateSettlementStatusRequestDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpEvent<any>>;
    public updateSettlementStatus(updateStatusSettlementActionCodeEnum: 'DELIVERY_AND_SECURITIES_CREATION_AND_PAYMENT_TO_BE_VALIDATED' | 'CANCEL' | 'REJECT' | 'VALIDATE' | 'DELIVERY_TO_BE_VALIDATED' | 'SECURITIES_CREATION_TO_BE_VALIDATED' | 'PAYMENT_TO_BE_VALIDATED', updateSettlementStatusRequestDto: UpdateSettlementStatusRequestDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any> {
        if (updateStatusSettlementActionCodeEnum === null || updateStatusSettlementActionCodeEnum === undefined) {
            throw new Error('Required parameter updateStatusSettlementActionCodeEnum was null or undefined when calling updateSettlementStatus.');
        }
        if (updateSettlementStatusRequestDto === null || updateSettlementStatusRequestDto === undefined) {
            throw new Error('Required parameter updateSettlementStatusRequestDto was null or undefined when calling updateSettlementStatus.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (updateStatusSettlementActionCodeEnum !== undefined && updateStatusSettlementActionCodeEnum !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>updateStatusSettlementActionCodeEnum, 'updateStatusSettlementActionCodeEnum');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/v1/instruments/_updateSettlementStatus`,
            updateSettlementStatusRequestDto,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateStatus
     * Update Status for Instrument or Tranche or Increase  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.instrument.update.status; Authorities: SGM_ISS_PAY_api.issuer-ipa.instrument.update.status
     * @param actionCodeEnum 
     * @param updateStatusRequestDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateStatus(actionCodeEnum: 'ISSUE' | 'FONG' | 'CANCEL' | 'CLOSE' | 'REOPEN', updateStatusRequestDto: UpdateStatusRequestDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any>;
    public updateStatus(actionCodeEnum: 'ISSUE' | 'FONG' | 'CANCEL' | 'CLOSE' | 'REOPEN', updateStatusRequestDto: UpdateStatusRequestDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpResponse<any>>;
    public updateStatus(actionCodeEnum: 'ISSUE' | 'FONG' | 'CANCEL' | 'CLOSE' | 'REOPEN', updateStatusRequestDto: UpdateStatusRequestDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpEvent<any>>;
    public updateStatus(actionCodeEnum: 'ISSUE' | 'FONG' | 'CANCEL' | 'CLOSE' | 'REOPEN', updateStatusRequestDto: UpdateStatusRequestDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any> {
        if (actionCodeEnum === null || actionCodeEnum === undefined) {
            throw new Error('Required parameter actionCodeEnum was null or undefined when calling updateStatus.');
        }
        if (updateStatusRequestDto === null || updateStatusRequestDto === undefined) {
            throw new Error('Required parameter updateStatusRequestDto was null or undefined when calling updateStatus.');
        }

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (actionCodeEnum !== undefined && actionCodeEnum !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>actionCodeEnum, 'actionCodeEnum');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/v1/instruments/_updateStatus`,
            updateStatusRequestDto,
            {
                params: queryParameters,
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * updateTranche
     * Update a tranche  **Authorization policy:**  Scopes: api.issuer-ipa.v1, api.issuer-ipa.instrument.update; Authorities: SGM_ISS_PAY_api.issuer-ipa.instrument.update
     * @param instrumentId 
     * @param trancheNumber 
     * @param instrumentTrancheUpdateDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateTranche(instrumentId: number, trancheNumber: number, instrumentTrancheUpdateDto: InstrumentTrancheUpdateDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any>;
    public updateTranche(instrumentId: number, trancheNumber: number, instrumentTrancheUpdateDto: InstrumentTrancheUpdateDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpResponse<any>>;
    public updateTranche(instrumentId: number, trancheNumber: number, instrumentTrancheUpdateDto: InstrumentTrancheUpdateDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<HttpEvent<any>>;
    public updateTranche(instrumentId: number, trancheNumber: number, instrumentTrancheUpdateDto: InstrumentTrancheUpdateDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/problem+json'}): Observable<any> {
        if (instrumentId === null || instrumentId === undefined) {
            throw new Error('Required parameter instrumentId was null or undefined when calling updateTranche.');
        }
        if (trancheNumber === null || trancheNumber === undefined) {
            throw new Error('Required parameter trancheNumber was null or undefined when calling updateTranche.');
        }
        if (instrumentTrancheUpdateDto === null || instrumentTrancheUpdateDto === undefined) {
            throw new Error('Required parameter instrumentTrancheUpdateDto was null or undefined when calling updateTranche.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (sgconnect) required
        credential = this.configuration.lookupCredential('sgconnect');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/problem+json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/v1/instruments/${encodeURIComponent(String(instrumentId))}/tranches/${encodeURIComponent(String(trancheNumber))}`,
            instrumentTrancheUpdateDto,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
